import React from 'react'
import styled from 'styled-components'
import { cx } from '../../../Utils/classnames'
import classes from './Field.module.css'

const Field = (props) => {
    const { children, className, direction = "row" } = props

    return (
        <Main className={cx(classes.main, className)} direction={direction}>
            {children}
        </Main>
    )
}

const Main = styled.div`
    flex-direction: ${({ direction }) => direction};
`

export default Field