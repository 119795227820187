import classes from "./FaqSection.module.css"
import { AiOutlineMinus } from "react-icons/ai"
import CustomButton from "../../../UI/CustomButton/CustomButton"
import { Controller } from "react-hook-form"
import Input from "../../../UI/Form/Input"
import CustomMarkdown from "../../../UI/Form/Markdown/CustomMarkdown"

const FaqSection = (props) => {
    const { index, control, onRemove } = props

    return (
        <div className={classes.main}>
            <div className={classes.header}>
                <h4>Question {index + 1}</h4>
                <CustomButton
                    className={classes.removeBut}
                    onClick={() => onRemove(index)}
                >
                    <AiOutlineMinus />
                    <span>Remove</span>
                </CustomButton>
            </div>
            <Controller
                name={`faq.${index}.title`}
                control={control}
                defaultValue=""
                rules={{ required: "Required Field!" }}
                render={({
                    field: { onChange, value },
                    fieldState: { error }
                }) => (
                    <Input
                        onChange={onChange}
                        value={value}
                        error={error}
                        label="Title"
                    />
                )}
            />
            <Controller
                name={`faq.${index}.title_en`}
                control={control}
                defaultValue=""
                rules={{ required: "Required Field!" }}
                render={({
                    field: { onChange, value },
                    fieldState: { error }
                }) => (
                    <Input
                        onChange={onChange}
                        value={value}
                        error={error}
                        label="Title (EN)"
                    />
                )}
            />
            <Controller
                name={`faq.${index}.text`}
                control={control}
                defaultValue=""
                rules={{ required: "Required Field!" }}
                render={({
                    field: { onChange, value },
                    fieldState: { error }
                }) => (
                    <CustomMarkdown
                        value={value}
                        onChange={onChange}
                        label={"Text"}
                        error={error}
                    />
                )}
            />
            <Controller
                name={`faq.${index}.text_en`}
                control={control}
                defaultValue=""
                rules={{ required: "Required Field!" }}
                render={({
                    field: { onChange, value },
                    fieldState: { error }
                }) => (
                    <CustomMarkdown
                        value={value}
                        onChange={onChange}
                        label={"Text (EN)"}
                        error={error}
                    />
                )}
            />
        </div>
    )
}

export default FaqSection
