import { Table } from '@mui/material'
import { TableHead } from '@mui/material'
import { TableCell } from '@mui/material'
import { TableBody } from '@mui/material'
import { TableRow } from '@mui/material'
import { TablePagination } from '@mui/material'
import { TableContainer, Paper } from '@mui/material'
import moment from 'moment'
import React from 'react'
import AdminControllButtons from '../../UI/Admin/AdminControllButtons/AdminControllButtons'
import EmptyData from '../../UI/Admin/EmpyData/EmptyData'
import CustomCheckbox from '../../UI/Form/CustomCheckbox'
import TableTh from '../../UI/Table/TableTh/TableTh'
import classes from '../AdminView.module.css'

const Tickets = (props) => {
    const {
        tickets,
        pageSize,
        pageNumber,
        total,
        handleChangePage,
        handlePageSize,
        handleRemove,
        handleEdit,
        searchValue,
        getTickets,
        resendTicket,
        setIsIcnludeFreeTickets,
        isIncludeFreeTickets
    } = props

    const rows = [
        {
            key: 'email',
            text: "Email",
            searchByValue: "userId"
        },
        {
            key: 'phone',
            text: "Phone",
            searchByValue: "phoneNumber"
        },
        {
            key: 'event',
            text: "Event",
            searchByValue: "eventId"
        },
        {
            key: 'price',
            text: "Price",
            searchByValue: "price"
        },
        {
            key: 'promo',
            text: "Promoter",
            searchByValue: "promo"
        },
        {
            key: 'date',
            text: "Date Time",
            searchByValue: "createdAt"
        },
        {
            key: '',
            text: "",
            searchByValue: ""
        }
    ]



    return (
        <div className={classes.main}>
            <TableContainer component={Paper} className={classes.table}>
                <Table>
                    <TableHead>
                        <TableRow>
                            {rows.map(item => (
                                <TableTh
                                    key={item.key}
                                    text={item.text}
                                    onSort={getTickets}
                                    searchByValue={item.searchByValue}
                                    searchValue={searchValue}
                                    pageNumber={pageNumber}
                                    pageSize={pageSize}
                                />
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tickets.map(item => (
                            <TableRow key={item._id}>
                                <TableCell>{item.user.email}</TableCell>
                                <TableCell>{item.user.phoneNumber}</TableCell>
                                <TableCell>{item.event ? item.event.title : ""}</TableCell>
                                <TableCell>{item.price}</TableCell>
                                <TableCell>{item.promo}</TableCell>
                                <TableCell>{moment(item.createdAt).format("DD/MM/YYYY HH:mm:ss")}</TableCell>
                                <TableCell width={120}>
                                    <AdminControllButtons
                                        item={item}
                                        onRemove={handleRemove}
                                        onEdit={handleEdit}
                                        resend={resendTicket}
                                        type="ticket"
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {tickets.length === 0 && <EmptyData/>}
            <div className={classes.footer}>
                <CustomCheckbox
                    label="Include free tickets"
                    checked={isIncludeFreeTickets}
                    onChange={(e) => setIsIcnludeFreeTickets(e.target.checked)}
                    disabled={!searchValue}
                />
                <TablePagination
                    rowsPerPageOptions={[20, 50, 100, 500]}
                    component={"div"}
                    rowsPerPage={pageSize}
                    page={pageNumber}
                    count={total}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handlePageSize}
                    className={classes.pagination}
                />
            </div>
        </div>
    )
}

export default Tickets