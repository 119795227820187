import React from 'react'
import { Controller } from 'react-hook-form'
import CustomButton from '../../../UI/CustomButton/CustomButton'
import Field from '../../../UI/Field/Field'
import CustomDatepicker from '../../../UI/Form/CustomDatepicker'
import Input from '../../../UI/Form/Input'
import classes from './PricingSection.module.css'
import { AiOutlineMinus } from 'react-icons/ai'

const PricingSection = (props) => {
    const { index, control, onRemove } = props

    return (
        <div className={classes.main}>
            <div className={classes.header}>
                <h4>Ticket Price (Level {index + 1})</h4>
                <CustomButton className={classes.removeBut} onClick={() => onRemove(index)}>
                    <AiOutlineMinus/>
                    <span>Remove</span>
                </CustomButton>
            </div>
            <Controller
                name={`pricing.${index}.price`}
                control={control}
                defaultValue=""
                rules={{ required: "Required Field!" }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <Input
                        onChange={onChange}
                        value={value}
                        error={error}
                        label="Price"  
                    />
                )}
            />
            <Field>
                <Controller
                    name={`pricing.${index}.start`}
                    control={control}
                    defaultValue=""
                    rules={{ required: "Required Field!" }}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <CustomDatepicker
                            onChange={onChange}
                            value={value}
                            error={error}
                            label="Start"  
                        />
                    )}
                />
                <Controller
                    name={`pricing.${index}.end`}
                    control={control}
                    defaultValue=""
                    rules={{ required: "Required Field!" }}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <CustomDatepicker
                            onChange={onChange}
                            value={value}
                            error={error}
                            label="End"  
                        />
                    )}
                />
            </Field>
        </div>
    )
}

export default PricingSection