import classes from './Styles.module.css'
import MDEditor from '@uiw/react-md-editor';

const CustomMarkdown = (props) => {
    const { value, onChange, label, error } = props

    return (
        <div className={classes.main}>
            {label && <label>{label}</label>}
            <MDEditor
                value={value}
                onChange={onChange}
                className={classes.editor}
                style={{ whiteSpace: 'pre-wrap' }}
            />
            {error && <span className={classes.error}>{error.message}</span>}
        </div>
    )
}

export default CustomMarkdown