import { Button } from '@mui/material'
import React from 'react'
import { cx } from '../../../Utils/classnames'
import classes from './CustomButton.module.css'

const CustomButton = (props) => {
    const { children, onClick, type = "button", className } = props

    return (
        <Button 
            onClick={onClick}
            type={type}
            className={cx(classes.main, className)}
        >
            {children}
        </Button>
    )
}

export default CustomButton