import { IconButton } from '@mui/material'
import React from 'react'
import classes from './AdminControllButtons.module.css'
import { MdModeEdit, MdDeleteForever, MdAutorenew } from 'react-icons/md';
import { Tooltip } from '@mui/material';

const AdminControllButtons = (props) => {
    const { onEdit, onRemove, item, type = "default", resend } = props

    const handleEdit = () => {
        onEdit(item)
    }

    const handleRemove = () => {
        onRemove(item)
    }

    const handleResend = () => {
        resend({
            userId: item.user._id,
            ticketId: item._id,
            eventId: item.event._id
        })
    }

    return (
        <div className={classes.main}>
            {type === "default" && (
                <>
                    <IconButton className={classes.editBut} onClick={handleEdit}>
                        <MdModeEdit/>
                    </IconButton>
                    <IconButton className={classes.delBut} onClick={handleRemove}>
                        <MdDeleteForever/>
                    </IconButton>
                </>
            )}
            {type === "ticket" && (
                <>
                    <Tooltip title="Resend ticket">
                        <IconButton className={classes.editBut} onClick={handleResend}>
                            <MdAutorenew/>
                        </IconButton>
                    </Tooltip>
                    <IconButton className={classes.delBut} onClick={handleRemove}>
                        <MdDeleteForever/>
                    </IconButton>
                </>
            )}
        </div>
    )
}

export default AdminControllButtons