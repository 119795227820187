import React from 'react'
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { TextField } from '@mui/material';

const CustomDatepicker = (props) => {
    const { 
        value,
        onChange,
        label,
        error
    } = props

    const material = {
        background: "#1A1A1A",
        width: "100%",
        color: "white",
        '& input::placeholder': {
            color: "white"
        },
        '& label.Mui-focused': {
            color: '#FF5500'
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#FF5500' 
        },
        '& .MuiOutlinedInput-root': {
            borderRadius: 0,
            color: "white",
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: "rgba(255, 85, 0, .3)"
        },
        '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: "rgba(255, 85, 0, .6)"
        },
        '& .MuiFormHelperText-root.Mui-error': {
            margin: 0,
            marginTop: 5,
        },
        '& .MuiInputLabel-root': {
            color: "gray"
        }
    }

    const handleChange = (newValue) => {
        onChange(newValue)
    }

    return (
        <MobileDatePicker
            label={label}
            inputFormat="DD/MM/yyyy"
            value={value}
            onChange={handleChange}
            renderInput={(params) => <TextField {...params} sx={material} error={!!error} />}
        />
    )
}

export default CustomDatepicker