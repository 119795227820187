import { useEffect } from "react"
import { Controller, useForm } from "react-hook-form"
import { connect } from "react-redux"
import CustomAutocomplete from "../../UI/Form/CustomAutocmplete"
import Modal from "../../UI/Modal/Modal"
import classes from '../AdminForm.module.css'
import { MdOutlineAlternateEmail } from 'react-icons/md'
import { FaMoneyBillAlt, FaTicketAlt } from 'react-icons/fa'
import { BsPhone } from 'react-icons/bs'
import Input from "../../UI/Form/Input"
import Field from "../../UI/Field/Field"
import CustomButton from "../../UI/CustomButton/CustomButton"

const CreateTicket = (props) => {
    const { onClose, createTicket, events } = props

    const { handleSubmit, reset, control, setValue } = useForm()

    const onSubmit = (data) => {
        data.eventId = data.eventId._id
        createTicket({
            ...data,
            price: Number(data.price)
        })
    }

    useEffect(() => {
        reset({
            eventId: null,
            email: "",
        })
    }, [])

    return (
        <Modal title="Create new Ticket's" onClose={onClose}>
            <form className={classes.main} onSubmit={handleSubmit(onSubmit)}>
                <Controller
                    name="eventId"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Required Field!" }}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <CustomAutocomplete
                            onChange={onChange}
                            value={value}
                            error={error}
                            name="eventId"
                            label="Event"
                            items={events}
                            setValue={setValue}
                        />
                    )}
                />
                <Field>
                    <Controller
                        name="email"
                        control={control}
                        defaultValue=""
                        rules={{ 
                            required: {
                                value: true,
                                message: "Required Field!"
                            },
                            pattern: {
                                value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}/,
                                message: "Invalid Email!"
                            }
                        }}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <Input
                                onChange={onChange}
                                value={value}
                                error={error}
                                label="User Email"  
                                placeholder="customer@gmail.com"
                                startAdornment={true}
                                startAdornmentIcon={<MdOutlineAlternateEmail/>}
                            />
                        )}
                    />
                    <Controller
                        name="phoneNumber"
                        control={control}
                        defaultValue=""
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <Input
                                onChange={onChange}
                                value={value}
                                label="Phone Number"
                                placeholder=""
                                startAdornment={true}
                                startAdornmentIcon={<BsPhone/>}
                            />
                        )}
                    />
                </Field>
                <Field>
                    <Controller
                        name="price"
                        control={control}
                        defaultValue={0}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <Input
                                onChange={onChange}
                                value={value}
                                label="Price for 1 ticket"
                                placeholder=""
                                regex="number"
                                startAdornment={true}
                                startAdornmentIcon={<FaMoneyBillAlt/>}
                            />
                        )}
                    />
                    <Controller
                        name="count"
                        control={control}
                        defaultValue={1}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <Input
                                onChange={onChange}
                                value={value}
                                label="Tickets count"
                                placeholder=""
                                regex="number"
                                startAdornment={true}
                                startAdornmentIcon={<FaTicketAlt/>}
                            />
                        )}
                    />
                </Field>
                <CustomButton type="submit">Create</CustomButton>
            </form>
        </Modal>
    )
}

let mapStateToProps = (state) => ({
    events: state.events.events
})

export default connect(mapStateToProps, {})(CreateTicket)