import React from "react"
import CustomButton from "../../../UI/CustomButton/CustomButton"
import classes from "./StageSection.module.css"
import { AiOutlineMinus } from "react-icons/ai"
import { useFieldArray } from "react-hook-form"
import { useEffect } from "react"
import DjField from "./DjField"

const StageSection = (props) => {
    const { index, control, onRemove, residents } = props

    const { fields, remove, append } = useFieldArray({
        control,
        name: `lineup.${index}.djs`
    })

    const addDj = () => {
        append({ name: "", info_link: "" })
    }

    const removeDj = (idx) => {
        if (fields.length > 1) {
            remove(idx)
        }
    }

    useEffect(() => {
        if (fields.length < 1) {
            addDj()
        }
    }, [])

    return (
        <div className={classes.main}>
            <div className={classes.header}>
                <h4>Stage {index + 1}</h4>
                <CustomButton
                    className={classes.removeBut}
                    onClick={() => onRemove(index)}
                >
                    <AiOutlineMinus />
                    <span>Remove</span>
                </CustomButton>
            </div>
            {fields.map((el, idx) => (
                <DjField
                    key={el.name}
                    el={el}
                    index={index}
                    idx={idx}
                    control={control}
                    removeDj={removeDj}
                    residents={residents}
                />
            ))}
            <CustomButton onClick={addDj}>Add DJ</CustomButton>
        </div>
    )
}

export default StageSection
