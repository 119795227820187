import { useEffect } from "react"
import { useState } from "react"
import { connect } from "react-redux"
import { getEvents } from "../../../Redux/eventsReducer"
import { createTicket, deleteTicket, getTickets, resendTicket, setTicketsData, setTotalTickets } from "../../../Redux/ticketsReducer"
import Preloader from "../../Common/Preloader/Preloader"
import AdminDeleteModal from "../../UI/Admin/AdminDeleteModal/AdminDeleteModal"
import AdminLayout from "../../UI/Admin/AdminLayout/AdminLayout"
import PageHeader from "../../UI/Admin/PageHeader/PageHeader"
import CreateTicket from "./CreateTicket"
import Tickets from "./Tickets"


const TicketsContainer = (props) => {
    const {
        isFetching,
        getTickets,
        getEvents,
        total,
        tickets,
        createTicket,
        setTotalTickets,
        setTicketsData,
        deleteTicket,
        resendTicket,
        events
    } = props

    const [pageSize, setPageSize] = useState(20)
    const [pageNumber, setPageNumber] = useState(0)

    const [searchValue, setSearchValue] = useState("")

    const [isIncludeFreeTickets, setIsIcnludeFreeTickets] = useState(true)

    const [currentItem, setCurrentItem] = useState(null)

    const [isOpenAdd, setIsOpenAdd] = useState(false)
    const [isOpenEdit, setIsOpenEdit] = useState(false)
    const [isOpenRemove, setIsOpenRemove] = useState(false)

    const handleOpenAdd = () => {
        setIsOpenAdd(!isOpenAdd)
    }

    const handleOpenEdit = (item) => {
        setCurrentItem(item)
        setIsOpenEdit(!isOpenEdit)
    }

    const handleOpenRemove = (item) => {
        setCurrentItem(item)
        setIsOpenRemove(!isOpenRemove)
    }

    const handleChangePage = (event, page) => {
        setPageNumber(page)
    }

    const handlePageSize = (event) => {
        setPageSize(event.target.value)
        setPageNumber(0)
    }

    const handleAdd = (data) => {
        createTicket(data).then(response => {
            if(response) {
                handleOpenAdd()
                getTickets(pageNumber + 1, pageSize, "", "", searchValue, isIncludeFreeTickets)
            }
        })
    }
    
    const handleRemove = (ticketId) => {
        deleteTicket(ticketId).then((status) => {
            if(status) {
                const newTickets = [...tickets]
                newTickets.forEach((item, index) => {
                    if(item._id === ticketId){
                        newTickets.splice(index, 1)
                        setTotalTickets(total - 1)
                    }
                })
                setIsOpenRemove(false)
                setTicketsData(newTickets)
            }
        })
    }

    useEffect(() => {
        getTickets(pageNumber + 1, pageSize, "", "", searchValue, isIncludeFreeTickets)
        getEvents(1, 500, "", "", "")
    }, [pageSize, pageNumber, isIncludeFreeTickets])

    return (
        <AdminLayout>
            <PageHeader 
                title="Tickets" 
                onAddClick={handleOpenAdd}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                pageSize={pageSize}
                onSearch={getTickets}
                isIncludeFreeTickets={isIncludeFreeTickets}
            />
            {isOpenAdd && <CreateTicket onClose={handleOpenAdd} createTicket={handleAdd} events={events}/>}
            {isOpenRemove && (
                <AdminDeleteModal
                    deleteItem={handleRemove}
                    onClose={handleOpenRemove}
                    onRemove={handleOpenRemove}
                    item={currentItem}
                    name={"Ticket"}
                />
            )}
            {isFetching && <Preloader/>}
            <Tickets
                tickets={tickets}
                pageSize={pageSize}
                pageNumber={pageNumber}
                total={total}
                handleChangePage={handleChangePage}
                handlePageSize={handlePageSize}
                handleEdit={handleOpenEdit}
                handleRemove={handleOpenRemove}
                onAddClick={handleOpenAdd}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                getTickets={getTickets}
                resendTicket={resendTicket}
                setIsIcnludeFreeTickets={setIsIcnludeFreeTickets}
                isIncludeFreeTickets={isIncludeFreeTickets}
            />
        </AdminLayout>
    )
}

let mapStateToProps = (state) => ({
    isFetching: state.common.isFetching,
    total: state.tickets.total,
    tickets: state.tickets.tickets,
    events: state.events.events
})

export default connect(mapStateToProps, {
    getTickets,
    getEvents,
    createTicket,
    setTicketsData,
    setTotalTickets,
    deleteTicket,
    resendTicket
})(TicketsContainer)