import React from 'react'
import classes from './Text.module.css'

const Text = (props) => {
    const { 
        variant,
        className,
        children
    } = props

    return (
        <>
            {variant === "error" && <p className={classes.error}>{children}</p>}
        </>
    )
}

export default Text