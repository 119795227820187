import { Table } from '@mui/material'
import { TableHead } from '@mui/material'
import { TableCell } from '@mui/material'
import { TableBody } from '@mui/material'
import { TableRow } from '@mui/material'
import { TablePagination } from '@mui/material'
import { TableContainer, Paper } from '@mui/material'
import React from 'react'
import AdminControllButtons from '../../UI/Admin/AdminControllButtons/AdminControllButtons'
import EmptyData from '../../UI/Admin/EmpyData/EmptyData'
import TableTh from '../../UI/Table/TableTh/TableTh'
import classes from '../AdminView.module.css'

const Employees = (props) => {
    const {
        employees,
        pageSize,
        pageNumber,
        total,
        handleChangePage,
        handlePageSize,
        handleRemove,
        handleEdit,
        searchValue,
        getEmployees
    } = props

    const rows = [
        {
            key: 'photo',
            text: "Photo",
            searchByValue: ""
        },
        {
            key: 'name',
            text: "Name",
            searchByValue: "name"
        },
        {
            key: 'role',
            text: "Role",
            searchByValue: "role"
        },
        {
            key: 'spec',
            text: "Specialisation",
            searchByValue: "spec"
        },
        {
            key: '',
            text: "",
            searchByValue: ""
        }
    ]

    return (
        <div className={classes.main}>
            <TableContainer component={Paper} className={classes.table}>
                <Table>
                    <TableHead>
                        <TableRow>
                            {rows.map(item => (
                                <TableTh
                                    key={item.key}
                                    text={item.text}
                                    onSort={getEmployees}
                                    searchByValue={item.searchByValue}
                                    searchValue={searchValue}
                                    pageNumber={pageNumber}
                                    pageSize={pageSize}
                                />
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {employees.map(item => (
                            <TableRow key={item._id}>
                                <TableCell width={"100px"}>
                                    <img src={item.photos[0]} alt="poster" className={classes.imgPreview}/>
                                </TableCell>
                                <TableCell>{item.name}</TableCell>
                                <TableCell sx={{ textTransform: "capitalize" }}>{item.role}</TableCell>
                                <TableCell>{item.spec}</TableCell>
                                <TableCell width={120}>
                                    <AdminControllButtons
                                        item={item}
                                        onRemove={handleRemove}
                                        onEdit={handleEdit}
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {employees.length === 0 && <EmptyData/>}
            <TablePagination
                rowsPerPageOptions={[5, 10, 20, 50]}
                component={"div"}
                rowsPerPage={pageSize}
                page={pageNumber}
                count={total}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handlePageSize}
                className={classes.pagination}
            />
        </div>
    )
}

export default Employees