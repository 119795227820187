import { authApi } from "../Api/api"
import { setIsFetching, setServerError } from "./commonReducer"

const SET_IS_AUTH = 'SET_IS_AUTH'
const SET_IS_STARTED_DATA = 'SET_IS_STARTED_DATA'
const SET_USER_DATA = 'SET_USER_DATA'

let initialState = {
    isAuth: false,
    isStartedData: false,
    user: null
}

let userReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_IS_AUTH: {
            return { ...state, isAuth: action.isAuth }
        }
        case SET_IS_STARTED_DATA: {
            return { ...state, isStartedData: action.isStartedData }
        }
        case SET_USER_DATA: {
            return { ...state, user: action.user }
        }
        default:
            return state
    }
}

export const setIsAuth = (isAuth) => ({
    type: SET_IS_AUTH, isAuth
})
export const setIsStartedData = (isStartedData) => ({
    type: SET_IS_STARTED_DATA, isStartedData
})
export const setUserData = (user) => ({
    type: SET_USER_DATA, user
})

export const login = (username, password) => async (dispatch) => {
    dispatch(setIsFetching(true))
    try {
        let response = await authApi.login(username, password)
        localStorage.accessToken = response.token
        dispatch([setIsAuth(true), setServerError(null), setIsFetching(false)])
    }catch(err) {
        dispatch([setServerError(err.response.data.message), setIsFetching(false)])
    }
}

export const me = () => async (dispatch) => {
    dispatch(setIsFetching(true))
    try {
        let response = await authApi.me()
        dispatch([setUserData(response), setIsStartedData(true), setIsAuth(true), setIsFetching(false)])
    }catch(err) {
        dispatch(setIsFetching(false))
    }
}

export const logout = () => async (dispatch) => {
    dispatch(setIsFetching(true))
    try {
        localStorage.accessToken = ""
        dispatch([setIsAuth(false), setIsStartedData(false), setUserData(null), setIsFetching(false)])
    }catch(err) {
        dispatch(setIsFetching(false))
    }
}

export default userReducer