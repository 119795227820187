import { Button } from '@mui/material'
import React from 'react'
import Modal from '../../Modal/Modal'
import classes from './AdminDeleteModal.module.css'

const AdminDeleteModal = (props) => {
    const { onClose, onRemove, deleteItem, item, name } = props

    const handleRemove = () => {
        deleteItem(item._id)
        onRemove(null)
    }

    return (
        <Modal title={`Delete ${name}`} onClose={onClose}>
            <p className={classes.text}>The action cannot be undone. Are you sure?</p>
            <div className={classes.footer}>
                <Button onClick={handleRemove} color="warning" className={classes.remove}>Remove</Button>
                <Button onClick={onClose}>Cancel</Button>
            </div>
        </Modal>
    )
}

export default AdminDeleteModal