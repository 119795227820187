import { useEffect } from "react"
import { Controller, useForm } from "react-hook-form"
import DropZone from "../../Common/DropZone/DropZone"
import Field from "../../UI/Field/Field"
import Input from "../../UI/Form/Input"
import CustomMarkdown from "../../UI/Form/Markdown/CustomMarkdown"
import Modal from "../../UI/Modal/Modal"
import { ImEmbed2 } from "react-icons/im"
import CustomButton from "../../UI/CustomButton/CustomButton"
import classes from '../AdminForm.module.css'

const CreatePost = (props) => {
    const { onClose, addPost } = props

    const { handleSubmit, reset, control, watch } = useForm()

    const onSubmit = (data) => {
        addPost(data)
    }

    useEffect(() => {
        if (localStorage.getItem("add_news_info")) {
            const rememberedInfo = JSON.parse(
                localStorage.getItem("add_news_info")
            )

            if (rememberedInfo) {
                reset({ ...rememberedInfo })
            }
        }
    }, [])

    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            localStorage.setItem("add_news_info", JSON.stringify(value))

            return () => subscription.unsubscribe()
        })
    }, [watch])

    return (
        <Modal title={"New Post"} onClose={onClose}>
            <form className={classes.main} onSubmit={handleSubmit(onSubmit)}>
                <Field>
                    <Controller
                        name="title"
                        control={control}
                        defaultValue=""
                        rules={{ required: "Required Field!" }}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <Input
                                onChange={onChange}
                                value={value}
                                error={error}
                                label="Title"  
                            />
                        )}
                    />
                </Field>
                <Field>
                    <Controller
                        name="title_en"
                        control={control}
                        defaultValue=""
                        rules={{ required: "Required Field!" }}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <Input
                                onChange={onChange}
                                value={value}
                                error={error}
                                label="Title (EN)"  
                            />
                        )}
                    />
                </Field>
                <div className={classes.imgContainer}>
                    <Controller
                        name={`image`}
                        control={control}
                        rules={{ required: "Required Field!" }}
                        defaultValue=""
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <DropZone
                                onChange={onChange}
                                error={error}
                                title="Poster"
                                id={1}
                                // initialFiles={[value]}
                            />
                        )}
                    />
                </div>
                <Controller
                    name="text"
                    control={control}
                    defaultValue=""
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <CustomMarkdown
                            value={value}
                            onChange={onChange}
                            label={"Text (UA)"}
                        />
                    )}
                />
                <Controller
                    name="text_en"
                    control={control}
                    defaultValue=""
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <CustomMarkdown
                            value={value}
                            onChange={onChange}
                            label={"Text (EN)"}
                        />
                    )}
                />
                <Controller
                    name="description"
                    control={control}
                    defaultValue=""
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <CustomMarkdown
                            value={value}
                            onChange={onChange}
                            label={"Description (UA)"}
                        />
                    )}
                />
                <Controller
                    name="description_en"
                    control={control}
                    defaultValue=""
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <CustomMarkdown
                            value={value}
                            onChange={onChange}
                            label={"Description (EN)"}
                        />
                    )}
                />
                <Field>
                    <Controller
                        name="keywords"
                        control={control}
                        defaultValue=""
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <Input
                                onChange={onChange}
                                value={value}
                                multiline
                                placeholder="Write inline (electroperedachi, techno, etc)"
                                label="Keywords (for SEO)"  
                            />
                        )}
                    />
                </Field>
                <Field>
                    <Controller
                        name="embedLink"
                        control={control}
                        defaultValue=""
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <Input
                                onChange={onChange}
                                value={value}
                                label="Embed element"
                                placeholder="Embed code"
                                startAdornment={true}
                                startAdornmentIcon={<ImEmbed2/>}
                            />
                        )}
                    />
                </Field>
                <CustomButton type="submit">Create</CustomButton>
            </form>
        </Modal>
    )
}

export default CreatePost