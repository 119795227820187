import { Alert } from "@mui/material"
import { useEffect, useState } from "react"
import { connect } from "react-redux"
import { setServerError, setServerResponse } from "../../../Redux/commonReducer"
import classes from './StatusMessage.module.css'

const TYPES = ["error", "success"]

const StatusMessage = (props) => {
    const { 
        serverError, 
        serverResponse,
        setServerError,
        setServerResponse
    } = props

    const [type, setType] = useState(TYPES[1])
    const [isShow, setIsShow] = useState(false)

    useEffect(() => {
        const TEXT_TIMER = 4000
        const SHOW_TIMER = 3000

        if (serverError) {
            setType(TYPES[0])
            setIsShow(true)
        }
        if (serverResponse) {
            setType(TYPES[1])
            setIsShow(true)
        }

        const showTimer = setTimeout(() => {
            setIsShow(false)
        }, SHOW_TIMER)

        const timer = setTimeout(() => {
            setServerResponse(null)
            setServerError(null)
        }, TEXT_TIMER)

        return () => { 
            clearTimeout(showTimer)
            clearTimeout(timer)
        }
    }, [serverError, serverResponse])

    return (
        <Alert
            severity={type}
            className={classes.main}
            sx={{
                bottom: `${isShow ? "2rem" : "-4rem"}`
            }}
        >
        {type === "success" && (
            <p>{serverResponse}</p>
        )}
        {type === "error" && (
            <p>{serverError}</p>
        )}
        </Alert>
    )
}

let mapStateToProps = (state) => ({
    serverError: state.common.serverError,
    serverResponse: state.common.serverResponse
})

export default connect(mapStateToProps, {
    setServerError,
    setServerResponse
})(StatusMessage)