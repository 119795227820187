import React from 'react'
import classes from '../AdminView.module.css'
import { Table } from '@mui/material'
import { TableHead } from '@mui/material'
import { TableCell } from '@mui/material'
import { TableBody } from '@mui/material'
import { TableRow } from '@mui/material'
import { TablePagination } from '@mui/material'
import { TableContainer, Paper } from '@mui/material'
import AdminControllButtons from '../../UI/Admin/AdminControllButtons/AdminControllButtons'
import EmptyData from '../../UI/Admin/EmpyData/EmptyData'
import TableTh from '../../UI/Table/TableTh/TableTh'
import moment from 'moment'

const Promocodes = (props) => {
    const {
        promocodes,
        pageSize,
        pageNumber,
        total,
        handleChangePage,
        handlePageSize,
        handleRemove,
        handleEdit,
        searchValue,
        getPromocodes
    } = props

    const rows = [
        {
            key: "name",
            text: "Name",
            searchByValue: "name"
        },
        {
            key: "discount",
            text: "Discount",
            searchByValue: "discount"
        },
        {
            key: "count",
            text: "Count",
            searchByValue: "count"
        },
        {
            key: "date",
            text: "Work time",
            searchByValue: ""
        },
        {
            key: '',
            text: "",
            searchByValue: ""
        }
    ]

    return (
        <div className={classes.main}>
            <TableContainer component={Paper} className={classes.table}>
                <Table>
                    <TableHead>
                        <TableRow>
                            {rows.map(item => (
                                <TableTh
                                    key={item.key}
                                    text={item.text}
                                    onSort={getPromocodes}
                                    searchByValue={item.searchByValue}
                                    searchValue={searchValue}
                                    pageNumber={pageNumber}
                                    pageSize={pageSize}
                                />
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {promocodes.map(item => (
                            <TableRow key={item._id}>
                                <TableCell>{item.name}</TableCell>
                                <TableCell>{item.discount}%</TableCell>
                                <TableCell>{item.count || 0}</TableCell>
                                <TableCell>
                                    {moment(item.start).format("DD/MM/YYYY")} - {moment(item.end).format("DD/MM/YYYY")}
                                </TableCell>
                                <TableCell width={120}>
                                    <AdminControllButtons
                                        item={item}
                                        onRemove={handleRemove}
                                        onEdit={handleEdit}
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {promocodes.length === 0 && <EmptyData/>}
            <TablePagination
                rowsPerPageOptions={[5, 10, 20, 50]}
                component={"div"}
                rowsPerPage={pageSize}
                page={pageNumber}
                count={total}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handlePageSize}
                className={classes.pagination}
            />
        </div>
    )
}

export default Promocodes