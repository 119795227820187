import React from 'react'
import Overflow from '../../UI/Overflow/Overflow'
import classes from './Preloader.module.css'
import preloader from '../../../Assets/Images/preloader.svg'

const Preloader = (props) => {
    return (
        <Overflow>
            <img src={preloader} alt="preloader" className={classes.img}/>
        </Overflow>
    )
}

export default Preloader