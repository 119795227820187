import { eventsApi } from "../Api/api"
import { setIsFetching, setServerError, setServerResponse } from "./commonReducer"

const SET_EVENTS_DATA = 'SET_EVENTS_DATA'
const SET_NEW_EVENT = 'SET_NEW_EVENT'
const SET_TOTAL_EVENTS = 'SET_TOTAL_EVENTS'

let initialState = {
    events: [],
    total: 0,
    newEvent: null
}

let eventsReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_EVENTS_DATA: {
            return { ...state, events: action.events }
        }
        case SET_NEW_EVENT: {
            return { ...state, newEvent: action.newEvent }
        }
        case SET_TOTAL_EVENTS: {
            return { ...state, total: action.total }
        }
        default:
            return state
    }
}

export const setEventsData = (events) => ({
    type: SET_EVENTS_DATA, events
})
export const setNewEvent = (newEvent) => ({
    type: SET_NEW_EVENT, newEvent
})
export const setTotalEvents = (total) => ({
    type: SET_TOTAL_EVENTS, total
})

export const getEvents = (pageNumber, pageSize, searchBy, from, searchingValue) => async (dispatch) => {
    dispatch(setIsFetching(true))
    try {
        let response = await eventsApi.getEvents(pageNumber, pageSize, searchBy, from, searchingValue)
        dispatch([setTotalEvents(response.total), setEventsData(response.events), setIsFetching(false)])
    }catch(err) {
        dispatch(setIsFetching(false))
    }
}

export const createEvent = (data) => async (dispatch) => {
    dispatch(setIsFetching(true))
    try {
        let response = await eventsApi.createEvent(data)
        dispatch([setServerResponse(response.message), setNewEvent(response.event), setIsFetching(false)])
        localStorage.add_event_info = ""
        return true
    }catch(err) {
        dispatch([setServerError(err.response.data.message), setIsFetching(false)])
        return false
    }
}

export const updateEvent = (id, data) => async (dispatch) => {
    dispatch(setIsFetching(true))
    try {
        let response = await eventsApi.updateEvent(id, data)
        dispatch([setServerResponse(response.message), setNewEvent(response.event), setIsFetching(false)])
        return true
    }catch(err) {
        dispatch(setIsFetching(false))
        return false
    }
}

export const deleteEvent = (id) => async (dispatch) => {
    dispatch(setIsFetching(true))
    try {
        let response = await eventsApi.deleteEvent(id)
        dispatch([setServerResponse(response.message), setIsFetching(false)])
        return true
    }catch(err) {
        dispatch([setServerError(err.response.data.message), setIsFetching(false)])
        return false
    }
}

export default eventsReducer