import React from 'react'
import { useEffect } from 'react'
import { connect } from 'react-redux'
import { Navigate, Outlet } from 'react-router-dom'
import Preloader from '../../Components/Common/Preloader/Preloader'
import { me } from '../../Redux/userReducer'

const Admin = (props) => {
    const {
        me,
        isAuth,
        isFetching,
        user
    } = props

    useEffect(() => {
        if(localStorage.accessToken) {
            me()
        }
    }, [localStorage.accessToken, isAuth])

    return (isFetching && !user) ? <Preloader/> :
    (isAuth && user) ? <Outlet/> : <Navigate to="/login" />
}

let mapStateToProps = (state) => ({
    isAuth: state.user.isAuth,
    isFetching: state.common.isFetching,
    user: state.user.user
})

export default connect(mapStateToProps, {
    me
})(Admin)