import { useEffect } from "react"
import { useState } from "react"
import { connect } from "react-redux"
import { createPartner, deletePartner, getPartners, setNewPartner, setPartnersData, setTotalPartners, updatePartner } from "../../../Redux/partnersReducer"
import Preloader from "../../Common/Preloader/Preloader"
import AdminDeleteModal from "../../UI/Admin/AdminDeleteModal/AdminDeleteModal"
import AdminLayout from "../../UI/Admin/AdminLayout/AdminLayout"
import PageHeader from "../../UI/Admin/PageHeader/PageHeader"
import PartnerCreate from "./PartnerCreate"
import Partners from "./Partners"
import PartnerUpdate from "./PartnerUpdate"

const PartnersContainer = (props) => {
    const {
        isFetching,
        getPartners,
        createPartner,
        setPartnersData,
        setNewPartner,
        newPartner,
        partners,
        total,
        updatePartner,
        deletePartner,
        setTotalPartners
    } = props

    const [pageSize, setPageSize] = useState(20)
    const [pageNumber, setPageNumber] = useState(0)

    const [searchValue, setSearchValue] = useState("")

    const [currentItem, setCurrentItem] = useState(null)

    const [isOpenAdd, setIsOpenAdd] = useState(false)
    const [isOpenEdit, setIsOpenEdit] = useState(false)
    const [isOpenRemove, setIsOpenRemove] = useState(false)

    const handleOpenAdd = () => {
        setIsOpenAdd(!isOpenAdd)
    }

    const handleOpenEdit = (item) => {
        setCurrentItem(item)
        setIsOpenEdit(!isOpenEdit)
    }

    const handleOpenRemove = (item) => {
        setCurrentItem(item)
        setIsOpenRemove(!isOpenRemove)
    }

    const handleChangePage = (event, page) => {
        setPageNumber(page)
    }

    const handlePageSize = (event) => {
        setPageSize(event.target.value)
        setPageNumber(0)
    }

    const handleAdd = (data) => {
        createPartner(data).then(response => {
            if(response) {
                handleOpenAdd()
            }
        })
    }

    const handleRemove = (partnerId) => {
        deletePartner(partnerId).then((status) => {
            if(status) {
                const newPartners = [...partners]
                newPartners.forEach((item, index) => {
                    if(item._id === partnerId){
                        newPartners.splice(index, 1)
                        setTotalPartners(total - 1)
                    }
                })
                setIsOpenRemove(false)
                setPartnersData(newPartners)
            }
        })
    }

    useEffect(() => {
        if(newPartner){
            const newPartners = [...partners]
            let pushIndex = newPartners.length
            newPartners.forEach((item, index) => {
                if(item._id === newPartner._id){
                    newPartners.splice(index, 1)
                    pushIndex = index
                }
            })
            newPartners.splice(pushIndex, 0, newPartner)
            setPartnersData(newPartners)
            setTotalPartners(total + 1)
            setNewPartner(null)
        }
    }, [newPartner])

    useEffect(() => {
        getPartners(pageNumber + 1, pageSize, "", "", searchValue)
    }, [pageSize, pageNumber])

    return (
        <AdminLayout>
            <PageHeader
                title="Partners" 
                onAddClick={handleOpenAdd}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                pageSize={pageSize}
                onSearch={getPartners}
            />
            {isOpenAdd && <PartnerCreate onClose={handleOpenAdd} addPartner={handleAdd}/>}
            {isOpenEdit && <PartnerUpdate onClose={handleOpenEdit} editPartner={updatePartner} partner={currentItem}/>}
            {isOpenRemove && (
                <AdminDeleteModal
                    deleteItem={handleRemove}
                    onClose={handleOpenRemove}
                    onRemove={handleOpenRemove}
                    item={currentItem}
                    name={currentItem.title}
                />
            )}
            {isFetching && <Preloader/>}
            <Partners
                partners={partners}
                pageSize={pageSize}
                pageNumber={pageNumber}
                total={total}
                handleChangePage={handleChangePage}
                handlePageSize={handlePageSize}
                handleEdit={handleOpenEdit}
                handleRemove={handleOpenRemove}
                onAddClick={handleOpenAdd}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                getPartners={getPartners}
            />
        </AdminLayout>
    )
}

let mapStateToProps = (state) => ({
    isFetching: state.common.isFetching,
    newPartner: state.partners.newPartner,
    partners: state.partners.partners,
    total: state.partners.total
})

export default connect(mapStateToProps, {
    getPartners,
    createPartner,
    updatePartner,
    deletePartner,
    setNewPartner,
    setTotalPartners,
    setPartnersData
})(PartnersContainer)