import CustomButton from '../../CustomButton/CustomButton'
import AdminSearch from '../Search/AdminSearch'
import classes from './PageHeader.module.css'

const PageHeader = (props) => {
    const { 
        title, 
        onAddClick,
        searchValue,
        setSearchValue,
        pageSize,
        onSearch,
        searchBar = true,
        createBut = true,
        isIncludeFreeTickets
    } = props

    return (
        <div className={classes.header}>
            <h2>{title}</h2>
            <div className={classes.right}>
                {searchBar && (
                    <AdminSearch 
                        searchValue={searchValue}
                        setSearchValue={setSearchValue}
                        pageSize={pageSize}
                        onSearch={onSearch}
                        isIncludeFreeTickets={isIncludeFreeTickets}
                    />
                )}
                {createBut && (
                    <CustomButton onClick={onAddClick} className={classes.button}>
                        Create
                    </CustomButton>
                )}
            </div>
        </div>
    )
}

export default PageHeader