import { newsApi } from "../Api/api"
import { setIsFetching, setServerError, setServerResponse } from "./commonReducer"

const SET_NEWS_DATA = 'SET_NEWS_DATA'
const SET_NEW_POST = 'SET_NEW_POST'
const SET_TOTAL_NEWS = 'SET_TOTAL_NEWS'

const initialState = {
    news: [],
    total: 0,
    newPost: null
}

const newsReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_NEWS_DATA: {
            return { ...state, news: action.news }
        }
        case SET_TOTAL_NEWS: {
            return { ...state, total: action.total }
        }
        case SET_NEW_POST: {
            return { ...state, newPost: action.newPost }
        }
        default: {
            return state
        }
    }
}

export const setNewsData = (news) => ({
    type: SET_NEWS_DATA, news
})
export const setNewPost = (newPost) => ({
    type: SET_NEW_POST, newPost
})
export const setTotalNews = (total) => ({
    type: SET_TOTAL_NEWS, total
})

export const getNews = (pageNumber, pageSize, searchBy, from, searchingValue) => async (dispatch) => {
    dispatch(setIsFetching(true))
    try {
        let response = await newsApi.getNews(pageNumber, pageSize, searchBy, from, searchingValue)
        dispatch([setTotalNews(response.total), setNewsData(response.news), setIsFetching(false)])
    } catch (err) {
        dispatch(setIsFetching(false))
    }
}

export const createPost = (data) => async (dispatch) => {
    dispatch(setIsFetching(true))
    try {
        let response = await newsApi.createNews(data)
        dispatch([setServerResponse(response.message), setNewPost(response.post), setIsFetching(false)])
        return true
    } catch (err) {
        dispatch([setServerError(err.response.data.message), setIsFetching(false)])
        return false
    }
}

export const updatePost = (postId, data) => async (dispatch) => {
    dispatch(setIsFetching(true))
    try {
        let response = await newsApi.updateNews(postId, data)
        dispatch([setServerResponse(response.message), setNewPost(response.post), setIsFetching(false)])
    } catch (err) {
        dispatch([setServerError(err.response.data.message), setIsFetching(false)])
    }
}

export const deletePost = (postId) => async (dispatch) => {
    dispatch(setIsFetching(true))
    try {
        let response = await newsApi.deleteNews(postId)
        dispatch([setServerResponse(response.message), setIsFetching(false)])
        return true
    } catch (err) {
        dispatch([setServerError(err.response.data.message), setIsFetching(false)])
        return false
    }
}

export default newsReducer