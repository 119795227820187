import { Controller, useForm } from "react-hook-form"
import Modal from "../../UI/Modal/Modal"
import classes from '../AdminForm.module.css'
import { AiOutlinePercentage } from "react-icons/ai"
import CustomDatepicker from "../../UI/Form/CustomDatepicker"
import CustomButton from "../../UI/CustomButton/CustomButton"
import Input from "../../UI/Form/Input"

const AddPromocode = (props) => {
    const { onClose, createPromocode } = props

    const { handleSubmit, reset, control } = useForm()

    const onSubmit = (data) => {
        createPromocode(data)
    }

    return (
        <Modal title="Create new Promocode" onClose={onClose}>
            <form className={classes.main} onSubmit={handleSubmit(onSubmit)}>
                <Controller
                    name="name"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Required Field!" }}
                    render={({
                        field: { onChange, value },
                        fieldState: { error }
                    }) => (
                        <Input
                            onChange={onChange}
                            value={value}
                            error={error}
                            label="Promocode name"
                        />
                    )}
                />
                <Controller
                    name="discount"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Required Field!" }}
                    render={({
                        field: { onChange, value },
                        fieldState: { error }
                    }) => (
                        <Input
                            onChange={onChange}
                            value={value}
                            error={error}
                            placeholder="Enter number"
                            label="Discount"
                            startAdornment={true}
                            startAdornmentIcon={<AiOutlinePercentage/>}
                        />
                    )}
                />
                <Controller
                    name="start"
                    control={control}
                    defaultValue=""
                    render={({
                        field: { onChange, value },
                        fieldState: { error }
                    }) => (
                        <CustomDatepicker
                            onChange={onChange}
                            value={value}
                            error={error}
                            label="Start"
                        />
                    )}
                />
                <Controller
                    name="end"
                    control={control}
                    defaultValue=""
                    render={({
                        field: { onChange, value },
                        fieldState: { error }
                    }) => (
                        <CustomDatepicker
                            onChange={onChange}
                            value={value}
                            error={error}
                            label="End"
                        />
                    )}
                />
                <CustomButton type="submit">Create</CustomButton>
            </form>
        </Modal>
    )
}

export default AddPromocode