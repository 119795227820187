import { MenuItem } from "@mui/material"
import { Controller, useForm } from "react-hook-form"
import Field from "../../UI/Field/Field"
import CustomSelect from "../../UI/Form/CustomSelect"
import Input from "../../UI/Form/Input"
import CustomMarkdown from "../../UI/Form/Markdown/CustomMarkdown"
import Modal from "../../UI/Modal/Modal"
import classes from '../AdminForm.module.css'
import { GrFacebook, GrSoundcloud, GrYoutube } from "react-icons/gr"
import { RiInstagramFill } from "react-icons/ri"
import { useEffect } from "react"
import DropZone from "../../Common/DropZone/DropZone"
import CustomButton from "../../UI/CustomButton/CustomButton"
import { useState } from "react"

const EditEmployee = (props) => {
    const { onClose, editEmployee, employee } = props

    const { handleSubmit, reset, control, setValue } = useForm()

    const [role, setRole] = useState(employee.role)

    const onSubmit = (data) => {
        editEmployee(employee._id, data)
    }

    useEffect(() => {
        if(role) {
            setValue("role", role)
        }
    }, [role])

    useEffect(() => {
        reset({ ...employee })
    }, [])

    return (
        <Modal title={`Edit Employee ${employee.name}`} onClose={onClose}>
            <form className={classes.main} onSubmit={handleSubmit(onSubmit)}>
                <Controller
                    name="name"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Required Field!" }}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <Input
                            onChange={onChange}
                            value={value}
                            error={error}
                            label="Name"  
                        />
                    )}
                />
                <Field>
                    <Controller
                        name="role"
                        control={control}
                        defaultValue=""
                        rules={{ required: "Required Field!" }}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <CustomSelect
                                onChange={(e) => setRole(e.target.value)}
                                value={value}
                                error={error}
                                label="Role"  
                            >
                                <MenuItem value="artist">Artist</MenuItem>
                                <MenuItem value="invited">Invited</MenuItem>
                                <MenuItem value="soft">Soft</MenuItem>
                            </CustomSelect>
                        )}
                    />
                    <Controller
                        name="spec"
                        control={control}
                        defaultValue=""
                        rules={{ required: "Required Field!" }}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <Input
                                onChange={onChange}
                                value={value}
                                error={error}
                                placeholder="DJ, Face Control, Photographer, etc..."
                                label="Specialisation"  
                            />
                        )}
                    />
                </Field>
                {role === "artist" && (
                    <Controller
                        name="real_name"
                        control={control}
                        defaultValue=""
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <Input
                                onChange={onChange}
                                value={value}
                                error={error}
                                label="Real Name"  
                            />
                        )}
                    />
                )}
                {role === "artist" && (
                    <Controller
                        name="bio"
                        control={control}
                        defaultValue=""
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <CustomMarkdown
                                value={value}
                                onChange={onChange}
                                label={"Bio"}
                            />
                        )}
                    />
                )}
                {role === "artist" && (
                    <Controller
                        name="bio_en"
                        control={control}
                        defaultValue=""
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <CustomMarkdown
                                value={value}
                                onChange={onChange}
                                label={"Bio (EN)"}
                            />
                        )}
                    />
                )}
                {role === "artist" && (
                    <Field>
                        <Controller
                            name="keywords"
                            control={control}
                            defaultValue=""
                            render={({
                                field: { onChange, value },
                                fieldState: { error }
                            }) => (
                                <Input
                                    onChange={onChange}
                                    value={value}
                                    multiline
                                    placeholder="Write inline (electroperedachi, techno, etc)"
                                    label="Keywords (for SEO)"
                                />
                            )}
                        />
                    </Field>
                )}
                {(role && role != "soft") && (
                    <Field direction="column">
                        <label>Social Links</label>
                        <Controller
                            name="links.facebook"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <Input
                                    onChange={onChange}
                                    value={value}
                                    label="Facebook Link"
                                    placeholder="https://..."
                                    startAdornment={true}
                                    startAdornmentIcon={<GrFacebook/>}
                                />
                            )}
                        />
                        <Controller
                            name="links.insta"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <Input
                                    onChange={onChange}
                                    value={value}
                                    label="Instagram Link"
                                    placeholder="https://..."
                                    startAdornment={true}
                                    startAdornmentIcon={<RiInstagramFill/>}
                                />
                            )}
                        />
                        <Controller
                            name="links.soundcloud"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <Input
                                    onChange={onChange}
                                    value={value}
                                    label="Soundcloud Link"
                                    placeholder="https://..."
                                    startAdornment={true}
                                    startAdornmentIcon={<GrSoundcloud/>}
                                />
                            )}
                        />
                    </Field>
                )}
                {role === "artist" && (
                    <Field direction="column">
                        <label>Media</label>
                        <Controller
                            name="music.0"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <Input
                                    onChange={onChange}
                                    value={value}
                                    label="Soundcloud embed DJ Mix 1"
                                    placeholder="https://..."
                                    startAdornment={true}
                                    startAdornmentIcon={<GrSoundcloud/>}
                                />
                            )}
                        />
                        <Controller
                            name="music.1"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <Input
                                    onChange={onChange}
                                    value={value}
                                    label="Soundcloud embed DJ Mix 2"
                                    placeholder="iframe html code"
                                    startAdornment={true}
                                    startAdornmentIcon={<GrSoundcloud/>}
                                />
                            )}
                        />
                        <Controller
                            name="video"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <Input
                                    onChange={onChange}
                                    value={value}
                                    label="YouTube embed video"
                                    placeholder="iframe html code"
                                    startAdornment={true}
                                    startAdornmentIcon={<GrYoutube/>}
                                />
                            )}
                        />
                    </Field>
                )}
                <Controller
                    name="photos"
                    control={control}
                    rules={{ required: "Required Field!" }}
                    defaultValue=""
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <>
                            <DropZone
                                onChange={onChange}
                                multiple
                                initialFiles={value}
                                error={error}
                                title="Photos"
                            />
                        </>
                    )}
                />
                <CustomButton type="submit">Save</CustomButton>
            </form>
        </Modal>
    )
}

export default EditEmployee