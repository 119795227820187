import { BrowserRouter as Router } from "react-router-dom";
import HttpsRedirect from 'react-https-redirect';
import './App.css';
import RoutesComponent from "./Components/Common/RoutesComponent";
import StatusMessage from "./Components/UI/StatusMesssage/StatusMessage";

const App = () => {
  return (
    <Router>
      <HttpsRedirect>
        <div className="main">
          <StatusMessage/>
          <RoutesComponent/>
        </div>
      </HttpsRedirect>
    </Router>
  )
}

export default App;
