import { employeeApi } from "../Api/api"
import { setIsFetching, setServerError, setServerResponse } from "./commonReducer"

const SET_EMPLOYEE_DATA = "SET_EMPLOYEE_DATA"
const SET_NEW_EMPLOYEE = "SET_NEW_EMPLOYEE"
const SET_TOTAL_EMPLOYEES = "SET_TOTAL_EMPLOYEES"

let initialState = {
    employees: [],
    total: 0,
    newEmployee: null
}

let employeesReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_EMPLOYEE_DATA: {
            return { ...state, employees: action.employees }
        }
        case SET_NEW_EMPLOYEE: {
            return { ...state, newEmployee: action.newEmployee }
        }
        case SET_TOTAL_EMPLOYEES: {
            return { ...state, total: action.total }
        }
        default:
            return state
    }
}

export const setEmployeesData = (employees) => ({
    type: SET_EMPLOYEE_DATA, employees
})

export const setNewEmployee = (newEmployee) => ({
    type: SET_NEW_EMPLOYEE, newEmployee
})

export const setTotalEmployees = (total) => ({
    type: SET_TOTAL_EMPLOYEES, total
})

export const getEmployees = (pageNumber, pageSize, searchBy, from, searchingValue) => async (dispatch) => {
    dispatch(setIsFetching(true))
    try {
        let response = await employeeApi.getEmployees(pageNumber, pageSize, searchBy, from, searchingValue)
        dispatch([setTotalEmployees(response.total), setEmployeesData(response.employees), setIsFetching(false)])
    } catch (err) {
        dispatch(setIsFetching(false))
    }
}

export const createEmployee = (data) => async (dispatch) => {
    dispatch(setIsFetching(true))
    try {
        let response = await employeeApi.createEmployee(data)
        dispatch([setServerResponse(response.message), setNewEmployee(response.employee), setIsFetching(false)])
        return true
    } catch (err) {
        dispatch([setServerError(err.response.data.message), setIsFetching(false)])
        return false
    }
}

export const updateEmployee = (id, data) => async (dispatch) => {
    dispatch(setIsFetching(true))
    try {
        let response = await employeeApi.updateEmployee(id, data)
        dispatch([setServerResponse(response.message), setNewEmployee(response.employee), setIsFetching(false)])
        return true
    } catch (err) {
        dispatch([setServerError(err.response.data.message), setIsFetching(false)])
        return false
    }
}

export const deleteEmployee = (id) => async (dispatch) => {
    dispatch(setIsFetching(true))
    try {
        let response = await employeeApi.deleteEmployee(id)
        dispatch([setServerResponse(response.message), setIsFetching(false)])
        return true
    } catch (err) {
        dispatch([setServerError(err.response.data.message), setIsFetching(false)])
        return false
    }
}

export default employeesReducer