import { useEffect } from "react"
import { useState } from "react"
import { connect } from "react-redux"
import { createPromocode, deletePromocode, getPromocodes, setNewPromocode, setPromocodesData, setTotalPromocodes, updatePromocode } from "../../../Redux/promocodeReduces"
import Preloader from "../../Common/Preloader/Preloader"
import AdminDeleteModal from "../../UI/Admin/AdminDeleteModal/AdminDeleteModal"
import AdminLayout from "../../UI/Admin/AdminLayout/AdminLayout"
import PageHeader from "../../UI/Admin/PageHeader/PageHeader"
import AddPromocode from "./AddPromocode"
import EditPromocode from "./EditPromocode"
import Promocodes from "./Promocodes"

const PromocodesContainer = (props) => {
    const {
        promocodes,
        isFetching,
        total,
        newPromocode,
        createPromocode,
        setTotalPromocodes,
        setPromocodesData,
        getPromocodes,
        deletePromocode,
        updatePromocode
    } = props

    const [pageSize, setPageSize] = useState(20)
    const [pageNumber, setPageNumber] = useState(0)

    const [searchValue, setSearchValue] = useState("")

    const [currentItem, setCurrentItem] = useState(null)

    const [isOpenAdd, setIsOpenAdd] = useState(false)
    const [isOpenEdit, setIsOpenEdit] = useState(false)
    const [isOpenRemove, setIsOpenRemove] = useState(false)

    const handleOpenAdd = () => {
        setIsOpenAdd(!isOpenAdd)
    }

    const handleOpenEdit = (item) => {
        setCurrentItem(item)
        setIsOpenEdit(!isOpenEdit)
    }

    const handleOpenRemove = (item) => {
        setCurrentItem(item)
        setIsOpenRemove(!isOpenRemove)
    }

    const handleChangePage = (event, page) => {
        setPageNumber(page)
    }

    const handlePageSize = (event) => {
        setPageSize(event.target.value)
        setPageNumber(0)
    }

    const handleAdd = (data) => {
        createPromocode(data).then(response => {
            if(response) {
                handleOpenAdd()
            }
        })
    }

    const handleRemove = (promocodeId) => {
        deletePromocode(promocodeId).then((status) => {
            if(status) {
                const newPromocodes = [...promocodes]
                newPromocodes.forEach((item, index) => {
                    if(item._id === promocodeId){
                        newPromocodes.splice(index, 1)
                        setTotalPromocodes(total - 1)
                    }
                })
                setIsOpenRemove(false)
                setPromocodesData(newPromocodes)
            }
        })
    }

    useEffect(() => {
        if(newPromocode){
            const newPromocodes = [...promocodes]
            let pushIndex = newPromocodes.length
            newPromocodes.forEach((item, index) => {
                if(item._id === newPromocode._id){
                    newPromocodes.splice(index, 1)
                    pushIndex = index
                }
            })
            newPromocodes.splice(pushIndex, 0, newPromocode)
            setPromocodesData(newPromocodes)
            setTotalPromocodes(total + 1)
            setNewPromocode(null)
        }
    }, [newPromocode])

    useEffect(() => {
        getPromocodes(pageNumber + 1, pageSize, "", "", searchValue)
    }, [pageSize, pageNumber])

    return (
        <AdminLayout>
            <PageHeader
                title="Promocodes"
                onAddClick={handleOpenAdd}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                pageSize={pageSize}
                onSearch={getPromocodes}
            />
            {isOpenAdd && <AddPromocode onClose={handleOpenAdd} createPromocode={handleAdd}/>}
            {isOpenEdit && <EditPromocode onClose={handleOpenEdit} editPromocode={updatePromocode} promocode={currentItem}/>}
            {isOpenRemove && (
                <AdminDeleteModal
                    deleteItem={handleRemove}
                    onClose={handleOpenRemove}
                    onRemove={handleOpenRemove}
                    item={currentItem}
                    name={currentItem.name}
                />
            )}
            {isFetching && <Preloader/>}
            <Promocodes
                promocodes={promocodes}
                pageSize={pageSize}
                pageNumber={pageNumber}
                total={total}
                handleChangePage={handleChangePage}
                handlePageSize={handlePageSize}
                handleEdit={handleOpenEdit}
                handleRemove={handleOpenRemove}
                onAddClick={handleOpenAdd}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                getPromocodes={getPromocodes}
            />
        </AdminLayout>
    )
}

let mapStateToProps = (state) => ({
    isFetching: state.common.isFetching,
    promocodes: state.promocodes.promocodes,
    total: state.promocodes.total,
    newPromocode: state.promocodes.newPromocode
})

export default connect(mapStateToProps, {
    createPromocode,
    setTotalPromocodes,
    setPromocodesData,
    getPromocodes,
    deletePromocode,
    updatePromocode
})(PromocodesContainer)