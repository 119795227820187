import { useEffect } from "react"
import { Controller, useForm } from "react-hook-form"
import DropZone from "../../Common/DropZone/DropZone"
import CustomButton from "../../UI/CustomButton/CustomButton"
import Field from "../../UI/Field/Field"
import Input from "../../UI/Form/Input"
import Modal from "../../UI/Modal/Modal"
import classes from '../AdminForm.module.css'

const PartnerUpdate = (props) => {
    const { onClose, editPartner, partner } = props

    const { handleSubmit, reset, control } = useForm()

    const onSubmit = (data) => {
        editPartner(partner._id, data)
    }

    useEffect(() => {
        reset({
            name: partner.name,
            url: partner.url,
            image: partner.image
        })
    }, [])

    return (
        <Modal title={`Edit Partner ${partner.name}`} onClose={onClose}>
            <form className={classes.main} onSubmit={handleSubmit(onSubmit)}>
                <Field>
                    <Controller
                        name="name"
                        control={control}
                        defaultValue=""
                        rules={{ required: "Required Field!" }}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <Input
                                onChange={onChange}
                                value={value}
                                error={error}
                                label="Partner name"  
                            />
                        )}
                    />
                </Field>
                <Field>
                    <Controller
                        name="url"
                        control={control}
                        defaultValue=""
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <Input
                                onChange={onChange}
                                value={value}
                                error={error}
                                label="Partner website"  
                            />
                        )}
                    />
                </Field>
                <div className={classes.imgContainer}>
                    <Controller
                        name={`image`}
                        control={control}
                        rules={{ required: "Required Field!" }}
                        defaultValue=""
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <DropZone
                                onChange={onChange}
                                error={error}
                                title="Partner image"
                                id={1}
                                initialFiles={[value]}
                            />
                        )}
                    />
                </div>
                <CustomButton type="submit">Update</CustomButton>
            </form>
        </Modal>
    )
}

export default PartnerUpdate