import { promocodeApi } from "../Api/api"
import { setIsFetching, setServerError, setServerResponse } from "./commonReducer"

const SET_PROMOCODES_DATA = 'SET_PROMOCODES_DATA'
const SET_NEW_PROMOCODE = 'SET_NEW_PROMOCODE'
const SET_TOTAL_PROMOCODES = 'SET_TOTAL_PROMOCODES'

const initialState = {
    promocodes: [],
    total: 0,
    newPromocode: null
}

const promocodeReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_PROMOCODES_DATA: {
            return { ...state, promocodes: action.promocodes }
        }
        case SET_TOTAL_PROMOCODES: {
            return { ...state, total: action.total }
        }
        case SET_NEW_PROMOCODE: {
            return { ...state, newPromocode: action.newPromocode }
        }
        default: {
            return state
        }
    }
}

export const setPromocodesData = (promocodes) => ({
    type: SET_PROMOCODES_DATA, promocodes
})
export const setNewPromocode = (newPromocode) => ({
    type: SET_NEW_PROMOCODE, newPromocode
})
export const setTotalPromocodes = (total) => ({
    type: SET_TOTAL_PROMOCODES, total
})

export const getPromocodes = (pageNumber, pageSize, searchBy, from, searchingValue) => async (dispatch) => {
    dispatch(setIsFetching(true))
    try {
        let response = await promocodeApi.getPromocodes(pageNumber, pageSize, searchBy, from, searchingValue)
        dispatch([setTotalPromocodes(response.total), setPromocodesData(response.promocodes), setIsFetching(false)])
    } catch (err) {
        dispatch(setIsFetching(false))
    }
}
export const createPromocode = (data) => async (dispatch) => {
    dispatch(setIsFetching(true))
    try {
        let response = await promocodeApi.createPromocode(data)
        dispatch([setServerResponse(response.message), setNewPromocode(response.promocode), setIsFetching(false)])
        return true
    } catch (err) {
        dispatch([setServerError(err.response.data.message), setIsFetching(false)])
        return false
    }
}
export const updatePromocode = (data, promocodeId) => async (dispatch) => {
    dispatch(setIsFetching(true))
    try {
        let response = await promocodeApi.updatePromocode(data, promocodeId)
        dispatch([setServerResponse(response.message), setNewPromocode(response.promocode), setIsFetching(false)])
        return true
    } catch (err) {
        dispatch([setServerError(err.response.data.message), setIsFetching(false)])
        return false
    }
}
export const deletePromocode = (promocodeId) => async (dispatch) => {
    dispatch(setIsFetching(true))
    try {
        let response = await promocodeApi.deletePromocode(promocodeId)
        dispatch([setServerResponse(response.message), setIsFetching(false)])
        return true
    } catch (err) {
        dispatch([setServerError(err.response.data.message), setIsFetching(false)])
        return false
    }
}

export default promocodeReducer