import { setIsFetching, setServerError, setServerResponse } from "./commonReducer"
import { partnersApi } from "../Api/api"

const SET_PARTNERS_DATA = 'SET_PARTNERS_DATA'
const SET_NEW_PARTNER = 'SET_NEW_PARTNER'
const SET_TOTAL_PARTNERS = 'SET_TOTAL_PARTNERS'

let initialState = {
    partners: [],
    total: 0,
    newPartner: null
}

const partnersReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_PARTNERS_DATA: {
            return { ...state, partners: action.partners }
        }
        case SET_TOTAL_PARTNERS: {
            return { ...state, total: action.total }
        }
        case SET_NEW_PARTNER: {
            return { ...state, newPartner: action.newPartner }
        }
        default: {
            return state
        }
    }
}

export const setPartnersData = (partners) => ({
    type: SET_PARTNERS_DATA, partners
})
export const setNewPartner = (newPartner) => ({
    type: SET_NEW_PARTNER, newPartner
})
export const setTotalPartners = (total) => ({
    type: SET_TOTAL_PARTNERS, total
})

export const getPartners = (pageNumber, pageSize, searchBy, from, searchingValue) => async (dispatch) => {
    dispatch(setIsFetching(true))
    try {
        let response = await partnersApi.getPartners(pageNumber, pageSize, searchBy, from, searchingValue)
        dispatch([setTotalPartners(response.total), setPartnersData(response.partners), setIsFetching(false)])
    }catch(err) {
        dispatch(setIsFetching(false))
    }
}

export const createPartner = (data) => async (dispatch) => {
    dispatch(setIsFetching(true))
    try {
        let response = await partnersApi.createPartner(data)
        dispatch([setServerResponse(response.message), setNewPartner(response.partner), setIsFetching(false)])
        return true
    }catch(err) {
        dispatch([setServerError(err.response.data.message), setIsFetching(false)])
        return false
    }
}

export const updatePartner = (id, data) => async (dispatch) => {
    dispatch(setIsFetching(true))
    try {
        let response = await partnersApi.updatePartner(id, data)
        dispatch([setServerResponse(response.message), setNewPartner(response.partner), setIsFetching(false)])
    }catch(err) {
        dispatch([setServerError(err.response.data.message), setIsFetching(false)])
    }
}

export const deletePartner = (id) => async (dispatch) => {
    dispatch(setIsFetching(true))
    try {
        let response = await partnersApi.deletePartner(id)
        dispatch([setServerResponse(response.message), setIsFetching(false)])
        return true
    }catch(err) {
        dispatch([setServerError(err.response.data.message), setIsFetching(false)])
        return false
    }
}

export default partnersReducer