import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { connect } from 'react-redux'
import { getEmployees } from '../../../Redux/employeeReducer'
import { createEvent, deleteEvent, getEvents, setEventsData, setNewEvent, setTotalEvents, updateEvent } from '../../../Redux/eventsReducer'
import { getPartners } from '../../../Redux/partnersReducer'
import { getPromocodes } from '../../../Redux/promocodeReduces'
import Preloader from '../../Common/Preloader/Preloader'
import AdminDeleteModal from '../../UI/Admin/AdminDeleteModal/AdminDeleteModal'
import AdminLayout from '../../UI/Admin/AdminLayout/AdminLayout'
import PageHeader from '../../UI/Admin/PageHeader/PageHeader'
import AddEvent from './AddEvent'
import AdminEvents from './AdminEvents'
import EditEvent from './EditEvent'

const AdminEventsContainer = (props) => {
    const { 
        isFetching,
        getEvents,
        createEvent,
        setEventsData,
        setNewEvent,
        newEvent,
        events,
        total,
        updateEvent,
        deleteEvent,
        setTotalEvents,
        getPartners,
        partners,
        employees,
        getEmployees,
        getPromocodes,
        promocodes
    } = props

    const [pageSize, setPageSize] = useState(20)
    const [pageNumber, setPageNumber] = useState(0)

    const [searchValue, setSearchValue] = useState("")

    const [currentItem, setCurrentItem] = useState(null)

    const [isOpenAdd, setIsOpenAdd] = useState(false)
    const [isOpenEdit, setIsOpenEdit] = useState(false)
    const [isOpenRemove, setIsOpenRemove] = useState(false)

    const [residents, setResidents] = useState([])

    const handleOpenAdd = () => {
        setIsOpenAdd(!isOpenAdd)
    }

    const handleOpenEdit = (item) => {
        setCurrentItem(item)
        setIsOpenEdit(!isOpenEdit)
    }

    const handleOpenRemove = (item) => {
        setCurrentItem(item)
        setIsOpenRemove(!isOpenRemove)
    }

    const handleChangePage = (event, page) => {
        setPageNumber(page)
    }

    const handlePageSize = (event) => {
        setPageSize(event.target.value)
        setPageNumber(0)
    }

    const handleAdd = (data) => {
        createEvent(data).then(response => {
            if(response) {
                handleOpenAdd()
                localStorage.setItem("add_event_info", "")
            }
        })
    }

    const handleRemove = (eventId) => {
        deleteEvent(eventId).then((status) => {
            if(status) {
                const newEvents = [...events]
                newEvents.forEach((item, index) => {
                    if(item._id === eventId){
                        newEvents.splice(index, 1)
                        setTotalEvents(total - 1)
                    }
                })
                setIsOpenRemove(false)
                setEventsData(newEvents)
            }
        })
    }

    useEffect(() => {
        if(newEvent){
            getEvents(pageNumber + 1, pageSize, "", "", searchValue)
            setNewEvent(null)
        }
    }, [newEvent])

    useEffect(() => {
        getEvents(pageNumber + 1, pageSize, "", "", searchValue)
        getPromocodes(1, 1000, "", "", "")
        getPartners(1, 1000, "", "", "")
        getEmployees(pageNumber + 1, 100, "", "", searchValue)
    }, [pageSize, pageNumber])

    useEffect(() => {
        if (employees) {
            const newResidents = employees.filter(el => el.role === "artist")
            setResidents(newResidents)
        }
    }, [employees])

    return (
        <AdminLayout>
            <PageHeader 
                title="Events" 
                onAddClick={handleOpenAdd}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                pageSize={pageSize}
                onSearch={getEvents}
            />
            {isOpenAdd && <AddEvent onClose={handleOpenAdd} addEvent={handleAdd} partners={partners} residents={residents}/>}
            {isOpenEdit && <EditEvent onClose={handleOpenEdit} editEvent={updateEvent} event={currentItem} partners={partners} residents={residents} promocodes={promocodes}/>}
            {isOpenRemove && (
                <AdminDeleteModal
                    deleteItem={handleRemove}
                    onClose={handleOpenRemove}
                    onRemove={handleOpenRemove}
                    item={currentItem}
                    name={currentItem.title}
                />
            )}
            {isFetching && <Preloader/>}
            <AdminEvents 
                events={events}
                pageSize={pageSize}
                pageNumber={pageNumber}
                total={total}
                handleChangePage={handleChangePage}
                handlePageSize={handlePageSize}
                handleEdit={handleOpenEdit}
                handleRemove={handleOpenRemove}
                onAddClick={handleOpenAdd}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                getEvents={getEvents}
            />
        </AdminLayout>
    )
}

let mapStateToProps = (state) => ({
    isFetching: state.common.isFetching,
    newEvent: state.events.newEvent,
    events: state.events.events,
    total: state.events.total,
    partners: state.partners.partners,
    employees: state.employees.employees,
    promocodes: state.promocodes.promocodes
})

export default connect(mapStateToProps, {
    getEvents,
    createEvent,
    setEventsData,
    setTotalEvents,
    setNewEvent,
    updateEvent,
    deleteEvent,
    getPartners,
    getEmployees,
    getPromocodes
})(AdminEventsContainer)
