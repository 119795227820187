import React from 'react'
import { Checkbox } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';

const CustomCheckbox = (props) => {
    const material = {
        color: "rgba(255, 85, 0, .3)",
        "&:hover": {
            backgroundColor: "rgba(255, 85, 0, .01) !important"
        },
        '&.Mui-checked': {
            color: "#FF5500 !important"
        }
    }

    const {
        label = "",
        checked,
        onChange,
        size = "medium",
        disabled = false
    } = props

    return (
        <FormControlLabel
            style={{ margin: 0 }}
            sx={{
                "& .MuiTypography-root": {
                    fontFamily: "Helvetica",
                    fontSize: "14px",
                    color: "whitesmoke"
                }
            }}
            control={
                <Checkbox 
                    checked={checked}
                    sx={material}
                    onChange={onChange}
                    size={size}
                    disabled={disabled}
                />
            } 
            label={label}
        />
    )
}

export default CustomCheckbox