import React from 'react'
import { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { connect } from 'react-redux'
import Preloader from '../../../Components/Common/Preloader/Preloader'
import CustomButton from '../../../Components/UI/CustomButton/CustomButton'
import Input from '../../../Components/UI/Form/Input'
import Modal from '../../../Components/UI/Modal/Modal'
import Text from '../../../Components/UI/Text/Text/Text'
import { login, me } from '../../../Redux/userReducer'
import { setServerError } from '../../../Redux/commonReducer'
import classes from './Login.module.css'
import { useNavigate } from 'react-router-dom'

const Login = (props) => {
    const { login, isFetching, serverError, setServerError, isAuth, me } = props

    const navigate = useNavigate()

    const { handleSubmit, control } = useForm()

    const onSubmit = (data) => {
        login(data.username, data.password)
    }

    useEffect(() => {
        if(isAuth) {
            navigate('/events')
        }
    }, [isAuth])

    useEffect(() => {
        me()
        return () => setServerError(null)
    }, [])

    return (
        <Modal title="Login" className={classes.container}>
            {isFetching && <Preloader/>}
            <form onSubmit={handleSubmit(onSubmit)} className={classes.main}>
                <Controller
                    name="username"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Required Field!" }}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <Input
                            onChange={onChange}
                            value={value}
                            error={error}
                            label="Username"  
                        />
                    )}
                />
                <Controller
                    name="password"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Required Field!" }}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <Input
                            onChange={onChange}
                            value={value}
                            error={error}
                            label="Password" 
                            type="password" 
                        />
                    )}
                />
                <CustomButton className={classes.submit} type="submit">Login</CustomButton>
                {serverError && <Text variant="error">{serverError}</Text>}
            </form>
        </Modal>
    )
}

let mapStateToProps = (state) => ({
    isFetching: state.common.isFetching,
    serverError: state.common.serverError,
    isAuth: state.user.isAuth
})

export default connect(mapStateToProps, {
    login,
    setServerError,
    me
})(Login)