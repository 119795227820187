import CustomButton from "../../../UI/CustomButton/CustomButton"
import Field from "../../../UI/Field/Field"
import CustomCheckbox from "../../../UI/Form/CustomCheckbox"
import { AiOutlineMinus } from "react-icons/ai"
import { Controller } from "react-hook-form"
import Input from "../../../UI/Form/Input"
import classes from "./StageSection.module.css"
import { cx } from "../../../../Utils/classnames"
import { useState } from "react"
import CustomSelect from "../../../UI/Form/CustomSelect"
import { MenuItem } from "@mui/material"
import { useEffect } from "react"

const DjField = (props) => {
    const { el, idx, index, control, removeDj, residents } = props

    const [isResident, setIsResident] = useState(true)
    const [residentLinks, setResidentLinks] = useState([])

    useEffect(() => {
        let resFound = false
        residents.forEach(r => {
            if (r.name === el.name) {
                resFound = true
                setResidentLinks(r.links)
            }
        })

        if (!resFound) {
            setIsResident(false)
        }
    }, [el, residents])

    return (
        <Field direction="column" key={el.id}>
            <div className={classes.header}>
                <h5>Artist {idx + 1}</h5>
                <div className={classes.actions}>
                    <CustomCheckbox
                        label="Resident"
                        checked={isResident}
                        onChange={(e) => setIsResident(e.target.checked)}
                    />
                    <CustomButton
                        className={cx(classes.removeBut, classes.smallRemove)}
                        onClick={() => removeDj(idx)}
                    >
                        <AiOutlineMinus />
                        <span>Remove</span>
                    </CustomButton>
                </div>
            </div>
            <Controller
                name={`lineup.${index}.djs.${idx}.name`}
                control={control}
                defaultValue=""
                rules={{ required: "Required Field!" }}
                render={({
                    field: { onChange, value },
                    fieldState: { error }
                }) =>
                    isResident ? (
                        <CustomSelect
                            value={value}
                            label="DJ"
                            onChange={(e) => {
                                residents.forEach(el => {
                                    if (el.name === e.target.value) {
                                        setResidentLinks(el.links)
                                    }
                                })
                                onChange(e)
                            }}
                            error={error}
                        >
                            <MenuItem disabled>Select a DJ</MenuItem>
                            {residents.map(el => (
                                <MenuItem key={el.name} value={el.name}>{el.name}</MenuItem>
                            ))}
                        </CustomSelect>
                    ) : (
                        <Input
                            onChange={onChange}
                            value={value}
                            error={error}
                            label="Name"
                        />
                    )
                }
            />
            <Controller
                name={`lineup.${index}.djs.${idx}.info_link`}
                control={control}
                defaultValue=""
                rules={{ required: "Required Field!" }}
                render={({
                    field: { onChange, value },
                    fieldState: { error }
                }) =>
                    (isResident && residentLinks) ? (
                        <CustomSelect
                            error={error}
                            value={value}
                            onChange={onChange}
                            label="Info resource"
                        >
                            {
                                Object.keys(residentLinks).map(function(key) {
                                    if (residentLinks[key]) {
                                        return (
                                            <MenuItem key={key} value={residentLinks[key]}>{key}</MenuItem>
                                        )
                                    }
                                })
                            }
                        </CustomSelect>
                    ) : (
                        <Input
                            onChange={onChange}
                            value={value}
                            error={error}
                            label="Info URL"
                        />
                    )
                }
            />
        </Field>
    )
}

export default DjField
