import React from 'react'
import Autocomplete from '@mui/material/Autocomplete';
import { TextField } from '@mui/material'

const CustomAutocomplete = (props) => {
    const { items, label, multiple = false, error, setValue, name, value, defaultValue = null } = props

    const material = {
        background: "transparent",
        color: "white",
        '& label.Mui-focused': {
            color: '#FF5500'
        },
        '& input::placeholder': {
            color: "white"
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#FF5500'
        },
        '& .MuiOutlinedInput-root': {
            borderRadius: 0,
            color: "white"
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: "rgba(255, 85, 0, .3)"
        },
        '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: "rgba(255, 85, 0, .6)"
        },
        '& .MuiFormHelperText-root.Mui-error': {
            margin: 0,
            marginTop: 5,
        },
        '& .MuiInputLabel-root': {
            color: "gray"
        },
        "& svg": {
            color: "rgba(255, 255, 255, .4)"
        },
    }

    return (
        <Autocomplete
            disablePortal
            options={items}
            value={value}
            getOptionLabel={option => option.name || option.title}
            onChange={(event, newValue) => {
                setValue(name, newValue)
            }}
            multiple={multiple}
            filterSelectedOptions
            limitTags={10}
            isOptionEqualToValue={(option, value) => option._id === value._id}
            style={{ width: "100%" }}
            defaultValue={defaultValue}
            renderInput={(params) => <TextField {...params} sx={material} label={label} error={!!error} helperText={error ? error.message : null} />}
            sx={{
                "& .MuiChip-root": {
                    backgroundColor: "#e45006",
                    color: "white"
                }
            }}
        />
    )
}

export default CustomAutocomplete