import { MdDashboard } from 'react-icons/md';
import { IoIosPeople } from 'react-icons/io';
import { IoTicketSharp } from 'react-icons/io5'
import { GiShakingHands } from 'react-icons/gi'
import { TbDiscount } from "react-icons/tb"
import { BiNews } from 'react-icons/bi'

export const useAdminSidebar = () => {
    const items = [
        {
            title: "Dashboard",
            icon: <MdDashboard/>,
            href: "/"
        },
        {
            title: "Events",
            icon: <p>e</p>,
            href: "/events"
        },
        {
            title: "Tickets",
            icon: <IoTicketSharp/>,
            href: "/tickets"
        },
        {
            title: "Team",
            icon: <IoIosPeople/>,
            href: "/team"
        },
        {
            title: "Partners",
            icon: <GiShakingHands/>,
            href: "/partners"
        },
        {
            title: "News",
            icon: <BiNews/>,
            href: "/news"
        },
        {
            title: "Promocodes",
            icon: <TbDiscount/>,
            href: "/promocodes"
        }
    ]

    return items
}