import { useEffect } from "react"
import { useState } from "react"
import { connect } from "react-redux"
import { createEmployee, deleteEmployee, getEmployees, setEmployeesData, setNewEmployee, setTotalEmployees, updateEmployee } from "../../../Redux/employeeReducer"
import Preloader from "../../Common/Preloader/Preloader"
import AdminDeleteModal from "../../UI/Admin/AdminDeleteModal/AdminDeleteModal"

import AdminLayout from "../../UI/Admin/AdminLayout/AdminLayout"
import PageHeader from "../../UI/Admin/PageHeader/PageHeader"
import AddEmployee from "./AddEmployee"
import EditEmployee from "./EditEmployee"
import Employees from "./Employees"

const EmployeesContainer = (props) => {
    const {
        getEmployees,
        employees,
        total,
        isFetching,
        newEmployee,
        createEmployee,
        setTotalEmployees,
        setEmployeesData,
        deleteEmployee,
        setNewEmployee,
        updateEmployee
    } = props

    const [pageSize, setPageSize] = useState(20)
    const [pageNumber, setPageNumber] = useState(0)

    const [searchValue, setSearchValue] = useState("")

    const [currentItem, setCurrentItem] = useState(null)

    const [isOpenAdd, setIsOpenAdd] = useState(false)
    const [isOpenEdit, setIsOpenEdit] = useState(false)
    const [isOpenRemove, setIsOpenRemove] = useState(false)

    const handleOpenAdd = () => {
        setIsOpenAdd(!isOpenAdd)
    }

    const handleOpenEdit = (item) => {
        setCurrentItem(item)
        setIsOpenEdit(!isOpenEdit)
    }

    const handleOpenRemove = (item) => {
        setCurrentItem(item)
        setIsOpenRemove(!isOpenRemove)
    }

    const handleChangePage = (event, page) => {
        setPageNumber(page)
    }

    const handlePageSize = (event) => {
        setPageSize(event.target.value)
        setPageNumber(0)
    }

    const handleAdd = (data) => {
        createEmployee(data).then(response => {
            if(response) {
                handleOpenAdd()
            }
        })
    }

    const handleRemove = (employeeId) => {
        deleteEmployee(employeeId).then((status) => {
            if(status) {
                const newEmployees = [...employees]
                newEmployees.forEach((item, index) => {
                    if(item._id === employeeId){
                        newEmployees.splice(index, 1)
                        setTotalEmployees(total - 1)
                    }
                })
                setIsOpenRemove(false)
                setEmployeesData(newEmployees)
            }
        })
    }

    useEffect(() => {
        if(newEmployee){
            const newEmployees = [...employees]
            let pushIndex = newEmployees.length
            newEmployees.forEach((item, index) => {
                if(item._id === newEmployee._id){
                    newEmployees.splice(index, 1)
                    pushIndex = index
                }
            })
            newEmployees.splice(pushIndex, 0, newEmployee)
            setEmployeesData(newEmployees)
            setTotalEmployees(total + 1)
            setNewEmployee(null)
        }
    }, [newEmployee])

    useEffect(() => {
        getEmployees(pageNumber + 1, pageSize, "", "", searchValue)
    }, [pageSize, pageNumber])

    return (
        <AdminLayout>
            <PageHeader
                title="Team"
                onAddClick={handleOpenAdd}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                pageSize={pageSize}
                onSearch={getEmployees}
            />
            {isOpenAdd && <AddEmployee onClose={handleOpenAdd} addEmployee={handleAdd}/>}
            {isOpenEdit && <EditEmployee onClose={handleOpenEdit} editEmployee={updateEmployee} employee={currentItem}/>}
            {isOpenRemove && (
                <AdminDeleteModal
                    deleteItem={handleRemove}
                    onClose={handleOpenRemove}
                    onRemove={handleOpenRemove}
                    item={currentItem}
                    name={currentItem.name}
                />
            )}
            {isFetching && <Preloader/>}
            <Employees
                employees={employees}
                pageSize={pageSize}
                pageNumber={pageNumber}
                total={total}
                handleChangePage={handleChangePage}
                handlePageSize={handlePageSize}
                handleEdit={handleOpenEdit}
                handleRemove={handleOpenRemove}
                onAddClick={handleOpenAdd}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                getEmployees={getEmployees}
            />
        </AdminLayout>
    )
}

let mapStateToProps = (state) => ({
    isFetching: state.common.isFetching,
    employees: state.employees.employees,
    total: state.employees.total,
    newEmployee: state.employees.newEmployee
})

export default connect(mapStateToProps, {
    getEmployees,
    createEmployee,
    setTotalEmployees,
    setEmployeesData,
    deleteEmployee,
    updateEmployee,
    setNewEmployee
})(EmployeesContainer)