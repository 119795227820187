import React from "react"
import Modal from "../../UI/Modal/Modal"
import classes from "../AdminForm.module.css"
import { Controller, useFieldArray, useForm } from "react-hook-form"
import Field from "../../UI/Field/Field"
import Input from "../../UI/Form/Input"
import CustomDatepicker from "../../UI/Form/CustomDatepicker"
import { useEffect } from "react"
import PricingSection from "./PricingSection/PricingSection"
import CustomButton from "../../UI/CustomButton/CustomButton"
import { AiOutlinePlus } from "react-icons/ai"
import StageSection from "./StageSection/StageSection"
import DropZone from "../../Common/DropZone/DropZone"
import moment from "moment"
import CustomMarkdown from "../../UI/Form/Markdown/CustomMarkdown"
import CustomAutocomplete from "../../UI/Form/CustomAutocmplete"
import CustomCheckbox from "../../UI/Form/CustomCheckbox"
import FaqSection from "./FaqSection/FaqSection"
import { cx } from "../../../Utils/classnames"

const EditEvent = (props) => {
    const { onClose, editEvent, event, partners, residents, promocodes } = props

    const { handleSubmit, reset, control, setValue } = useForm()

    const {
        fields: priceFields,
        append: priceAppend,
        remove: priceRemove
    } = useFieldArray({ control, name: "pricing" })

    const addPriceSection = () => {
        priceAppend({ price: "", start: "", end: "" })
    }

    const removePriceSection = (index) => {
        if (priceFields.length > 1) {
            priceRemove(index)
        }
    }

    const {
        fields: lineup,
        append: stageAppend,
        remove: stageRemove
    } = useFieldArray({ control, name: "lineup" })

    const addStage = () => {
        stageAppend({})
    }

    const removeStage = (index) => {
        if (lineup.length > 1) {
            stageRemove(index)
        }
    }

    const {
        fields: faq,
        append: faqAppend,
        remove: faqRemove
    } = useFieldArray({ control, name: 'faq' })

    const addFaq = () => {
        faqAppend({ })
    }

    const removeFaq = (index) => {
        faqRemove(index)
    }

    const onSubmit = (data) => {
        if (data.promocode_id && data.promocode_id._id) {
            editEvent(event._id, {
                ...data,
                promocode_id: data.promocode_id._id
            })
        } else {
            editEvent(event._id, data)
        }
    }

    useEffect(() => {
        reset({
            title: event.title,
            title_code: event.title_code,
            date: moment(event.date),
            start: event.start,
            end: event.end,
            address: event.address,
            address_en: event.address_en,
            venue: event.venue,
            city: event.city,
            city_en: event.city_en,
            pricing: event.pricing,
            lineup: event.lineup,
            description: event.description,
            description_en: event.description_en,
            main_keys: event.main_keys,
            main_keys_en: event.main_keys_en,
            keywords: event.keywords,
            faq: event.faq,
            poster: {
                image: event.poster.image,
                video: event.poster.video
            },
            how_it_was: {
                photos: event.how_it_was
                    ? event.how_it_was.photos
                        ? event.how_it_was.photos
                        : []
                    : [],
                fullAlbumPhotosUrl: event.how_it_was
                    ? event.how_it_was.fullAlbumPhotosUrl
                    : "",
                video: event.how_it_was ? event.how_it_was.video : ""
            },
            partners: event.partners,
            is_featured: event.is_featured,
            is_unique_design: event.is_unique_design,
            image_on_ticket_form: event.image_on_ticket_form ? [event.image_on_ticket_form] : [],
            promocodes: promocodes.filter(el => el._id === event.promocodes.find(promocode => promocode === el._id)) || null,
            is_multi_buy: event.is_multi_buy,
            google_table_id: event.google_table_id
        })
    }, [event])

    return (
        <Modal title={`Edit Event ${event.title}`} onClose={onClose}>
            <form className={classes.main} onSubmit={handleSubmit(onSubmit)}>
                <Field>
                    <Controller
                        name="title"
                        control={control}
                        defaultValue=""
                        rules={{ required: "Required Field!" }}
                        render={({
                            field: { onChange, value },
                            fieldState: { error }
                        }) => (
                            <Input
                                onChange={onChange}
                                value={value}
                                error={error}
                                label="Title"
                            />
                        )}
                    />
                    <Controller
                        name="title_code"
                        control={control}
                        defaultValue=""
                        render={({
                            field: { onChange, value },
                            fieldState: { error }
                        }) => (
                            <Input
                                onChange={onChange}
                                value={value}
                                error={error}
                                label="URL code (optional, if empty will generate automaticaly)"
                            />
                        )}
                    />
                </Field>
                <Controller
                    name="date"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Required Field!" }}
                    render={({
                        field: { onChange, value },
                        fieldState: { error }
                    }) => (
                        <CustomDatepicker
                            onChange={onChange}
                            value={value}
                            error={error}
                            label="Date"
                        />
                    )}
                />
                <Field>
                    <Controller
                        name="start"
                        control={control}
                        defaultValue=""
                        rules={{ required: "Required Field!" }}
                        render={({
                            field: { onChange, value },
                            fieldState: { error }
                        }) => (
                            <Input
                                onChange={onChange}
                                value={value}
                                error={error}
                                label="Start"
                            />
                        )}
                    />
                    <Controller
                        name="end"
                        control={control}
                        defaultValue=""
                        rules={{ required: "Required Field!" }}
                        render={({
                            field: { onChange, value },
                            fieldState: { error }
                        }) => (
                            <Input
                                onChange={onChange}
                                value={value}
                                error={error}
                                label="End"
                            />
                        )}
                    />
                </Field>
                <Field>
                    <Controller
                        name="city"
                        control={control}
                        defaultValue=""
                        rules={{ required: "Required Field!" }}
                        render={({
                            field: { onChange, value },
                            fieldState: { error }
                        }) => (
                            <Input
                                onChange={onChange}
                                value={value}
                                error={error}
                                label="City"
                            />
                        )}
                    />
                    <Controller
                        name="city_en"
                        control={control}
                        defaultValue=""
                        rules={{ required: "Required Field!" }}
                        render={({
                            field: { onChange, value },
                            fieldState: { error }
                        }) => (
                            <Input
                                onChange={onChange}
                                value={value}
                                error={error}
                                label="City (EN)"
                            />
                        )}
                    />
                </Field>
                <Controller
                    name="venue"
                    control={control}
                    defaultValue=""
                    render={({
                        field: { onChange, value },
                        fieldState: { error }
                    }) => (
                        <Input
                            onChange={onChange}
                            value={value}
                            error={error}
                            label="Venue"
                        />
                    )}
                />
                <Field>
                    <Controller
                        name="address"
                        control={control}
                        defaultValue=""
                        rules={{ required: "Required Field!" }}
                        render={({
                            field: { onChange, value },
                            fieldState: { error }
                        }) => (
                            <Input
                                onChange={onChange}
                                value={value}
                                error={error}
                                label="Address"
                            />
                        )}
                    />
                    <Controller
                        name="address_en"
                        control={control}
                        defaultValue=""
                        rules={{ required: "Required Field!" }}
                        render={({
                            field: { onChange, value },
                            fieldState: { error }
                        }) => (
                            <Input
                                onChange={onChange}
                                value={value}
                                error={error}
                                label="Address (EN)"
                            />
                        )}
                    />
                </Field>
                <Controller
                    name="poster.video"
                    control={control}
                    defaultValue=""
                    render={({
                        field: { onChange, value },
                        fieldState: { error }
                    }) => (
                        <Input
                            onChange={onChange}
                            value={value}
                            error={error}
                            label="Video Teaser (YouTube Link)"
                        />
                    )}
                />
                <div className={classes.imgContainer}>
                    <Controller
                        name={`poster.image`}
                        control={control}
                        rules={{ required: "Required Field!" }}
                        defaultValue=""
                        render={({
                            field: { onChange, value },
                            fieldState: { error }
                        }) => (
                            <>
                                <DropZone
                                    onChange={onChange}
                                    error={error}
                                    title="Poster Image"
                                    id={1}
                                    initialFiles={[value]}
                                />
                            </>
                        )}
                    />
                </div>
                <Field className={classes.sectionHeader}>
                    <h3>Pricing</h3>
                    <CustomButton
                        className={classes.addBut}
                        onClick={addPriceSection}
                    >
                        <AiOutlinePlus />
                        <span>Add New Level</span>
                    </CustomButton>
                </Field>
                <Field direction="column">
                    {priceFields.map((el, index) => (
                        <PricingSection
                            key={el.id}
                            index={index}
                            control={control}
                            onRemove={removePriceSection}
                        />
                    ))}
                </Field>
                <Field direction="column">
                    <Field className={classes.sectionHeader}>
                        <h3>Line up</h3>
                        <CustomButton
                            className={classes.addBut}
                            onClick={addStage}
                        >
                            <AiOutlinePlus />
                            <span>Add New Stage</span>
                        </CustomButton>
                    </Field>
                    {lineup.map((el, index) => (
                        <StageSection
                            index={index}
                            control={control}
                            onRemove={removeStage}
                            key={el.id}
                            residents={residents}
                        />
                    ))}
                </Field>
                <Controller
                    name="description"
                    control={control}
                    defaultValue=""
                    render={({
                        field: { onChange, value },
                        fieldState: { error }
                    }) => (
                        <CustomMarkdown
                            value={value}
                            onChange={onChange}
                            label={"Description"}
                        />
                    )}
                />
                <Controller
                    name="description_en"
                    control={control}
                    defaultValue=""
                    render={({
                        field: { onChange, value },
                        fieldState: { error }
                    }) => (
                        <CustomMarkdown
                            value={value}
                            onChange={onChange}
                            label={"Description (EN)"}
                        />
                    )}
                />
                <Controller
                    name="main_keys"
                    control={control}
                    defaultValue=""
                    render={({
                        field: { onChange, value },
                        fieldState: { error }
                    }) => (
                        <CustomMarkdown
                            value={value}
                            onChange={onChange}
                            label={"Main Keys"}
                        />
                    )}
                />
                <Controller
                    name="main_keys_en"
                    control={control}
                    defaultValue=""
                    render={({
                        field: { onChange, value },
                        fieldState: { error }
                    }) => (
                        <CustomMarkdown
                            value={value}
                            onChange={onChange}
                            label={"Main Keys (EN)"}
                        />
                    )}
                />
                <Field>
                    <Controller
                        name="keywords"
                        control={control}
                        defaultValue=""
                        render={({
                            field: { onChange, value },
                            fieldState: { error }
                        }) => (
                            <Input
                                onChange={onChange}
                                value={value}
                                multiline
                                placeholder="Write inline (electroperedachi, techno, etc)"
                                label="Keywords (for SEO)"
                            />
                        )}
                    />
                </Field>
                <Field direction="column">
                    <Field className={classes.sectionHeader}>
                        <h3>FAQ</h3>
                    </Field>
                    {faq.map((el, index) => (
                        <FaqSection  
                            index={index} 
                            control={control} 
                            onRemove={removeFaq}
                            key={el.id}
                        />
                    ))}
                </Field>
                <Field>
                    <CustomButton className={cx(classes.addBut, classes.addFaq)} onClick={addFaq}>
                        <AiOutlinePlus/>
                        <span>Add FAQ section</span>
                    </CustomButton>
                </Field>
                <div style={{ marginTop: "10px" }}>
                    <Controller
                        name={"partners"}
                        control={control}
                        defaultValue={[]}
                        render={({
                            field: { onChange, value },
                            fieldState: { error }
                        }) => (
                            <CustomAutocomplete
                                value={value}
                                onChange={onChange}
                                items={partners}
                                multiple={true}
                                label={"Partners"}
                                error={error}
                                setValue={setValue}
                                name={"partners"}
                            />
                        )}
                    />
                </div>
                <Controller
                    name="is_featured"
                    control={control}
                    defaultValue=""
                    render={({
                        field: { onChange, value },
                        fieldState: { error }
                    }) => (
                        <CustomCheckbox
                            checked={value}
                            onChange={onChange}
                            label="Is Featured"
                        />
                    )}
                />
                <Controller
                    name="is_unique_design"
                    control={control}
                    defaultValue=""
                    render={({
                        field: { onChange, value },
                        fieldState: { error }
                    }) => (
                        <CustomCheckbox
                            checked={value}
                            onChange={onChange}
                            label="Have a Custom Landing Page"
                        />
                    )}
                />
                <Controller
                    name="how_it_was.video"
                    control={control}
                    defaultValue=""
                    render={({
                        field: { onChange, value },
                        fieldState: { error }
                    }) => (
                        <Input
                            onChange={onChange}
                            value={value}
                            error={error}
                            label="How it was aftermovie (YouTube Link)"
                        />
                    )}
                />
                <div className={classes.imgContainer}>
                    <Controller
                        name={`how_it_was.photos`}
                        control={control}
                        defaultValue=""
                        render={({
                            field: { onChange, value },
                            fieldState: { error }
                        }) => (
                            <>
                                <DropZone
                                    multiple={true}
                                    onChange={onChange}
                                    error={error}
                                    title="How it was photos"
                                    id={2}
                                    initialFiles={value}
                                />
                            </>
                        )}
                    />
                </div>
                <Controller
                    name="google_table_id"
                    control={control}
                    defaultValue=""
                    render={({
                        field: { onChange, value },
                        fieldState: { error }
                    }) => (
                        <Input
                            onChange={onChange}
                            value={value}
                            error={error}
                            label="Google table ID"
                        />
                    )}
                />
                <Controller
                    name="how_it_was.fullAlbumPhotosUrl"
                    control={control}
                    defaultValue=""
                    render={({
                        field: { onChange, value },
                        fieldState: { error }
                    }) => (
                        <Input
                            onChange={onChange}
                            value={value}
                            error={error}
                            label="How it was full photo album URL link"
                        />
                    )}
                />
                <Controller
                    name={"image_on_ticket_form"}
                    control={control}
                    defaultValue=""
                    render={({
                        field: { onChange, value },
                        fieldState: { error }
                    }) => (
                        <>
                            <DropZone
                                onChange={onChange}
                                error={error}
                                title="Image for ticket buy section (will placed default img if empty)"
                                id={3}
                                initialFiles={value}
                            />
                        </>
                    )}
                />
                <div style={{ marginTop: "10px" }}>
                    <Controller
                        name={"promocodes"}
                        control={control}
                        defaultValue={[]}
                        render={({
                            field: { onChange, value },
                            fieldState: { error }
                        }) => (
                            <CustomAutocomplete
                                value={value}
                                onChange={onChange}
                                items={promocodes}
                                multiple={true}
                                label={"Promocode"}
                                error={error}
                                setValue={setValue}
                                name={"promocodes"}
                            />
                        )}
                    />
                </div>
                <Controller
                    name="is_multi_buy"
                    control={control}
                    defaultValue=""
                    render={({
                        field: { onChange, value },
                        fieldState: { error }
                    }) => (
                        <CustomCheckbox
                            checked={value}
                            onChange={onChange}
                            label="Multi Buy Action"
                        />
                    )}
                />
                <CustomButton type="submit">Save</CustomButton>
            </form>
        </Modal>
    )
}

export default EditEvent
