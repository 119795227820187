import { connect } from "react-redux"
import AdminLayout from "../../UI/Admin/AdminLayout/AdminLayout"
import PageHeader from "../../UI/Admin/PageHeader/PageHeader"

const DashboardContainer = (props) => {
    return (
        <AdminLayout>
            <PageHeader
                title="Dashboard"
                searchBar={false}
                createBut={false}
            />
        </AdminLayout>
    )
}

let mapStateToProps = (state) => ({

})

export default connect(mapStateToProps, {

})(DashboardContainer)