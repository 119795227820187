import * as axios from "axios"
import i18next from "i18next"

// export const baseURL = "http://localhost:3002/api"
export const baseURL = "https://api.electroperedachi.com/api"

const instance = axios.create({
    baseURL: baseURL
})

instance.interceptors.request.use(
    (config) => {
        const token = localStorage.accessToken
        if (token) {
            config.headers.authorization = `Bearer ${token}`
        }
        config.headers["Accept-Language"] = i18next.language
        return config
    },
    (error) => Promise.reject(error)
)

export const authApi = {
    login(username, password) {
        return instance
            .post(`/admin/login`, { username, password })
            .then((response) => response.data)
    },
    me() {
        return instance.get("/admin/auth").then((response) => response.data)
    }
}

export const eventsApi = {
    getEvents(pageNumber, pageSize, searchBy, from, searchingValue) {
        return instance
            .get(
                `/events?limit=${pageSize}&count=${pageNumber}&search_by=${searchBy}&from=${from}&searchingValue=${searchingValue}`
            )
            .then((response) => response.data)
    },
    createEvent(data) {
        data.image = data.poster.image[0]

        return instance
            .post(
                `/events`,
                { image: data.poster.image[0], image_on_ticket_form: data.image_on_ticket_form[0], data: JSON.stringify(data) },
                {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                }
            )
            .then((response) => response.data)
    },
    updateEvent(id, data) {
        data.image = data.poster.image[0]
        const formImage = data.image_on_ticket_form[0] ? data.image_on_ticket_form[0] : ""

        return instance
            .patch(
                `/events/${id}`,
                {
                    image: data.poster.image[0],
                    image_on_ticket_form: formImage,
                    photos: data.how_it_was ? (data.how_it_was.photos ? data.how_it_was.photos : null) : null,
                    data: JSON.stringify(data)
                },
                {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                }
            )
            .then((response) => response.data)
    },
    deleteEvent(id) {
        return instance
            .delete(`/events/${id}`)
            .then((response) => response.data)
    }
}

export const employeeApi = {
    getEmployees(pageNumber, pageSize, searchBy, from, searchingValue) {
        return instance
            .get(
                `/employee?limit=${pageSize}&count=${pageNumber}&search_by=${searchBy}&from=${from}&searchingValue=${searchingValue}`
            )
            .then((response) => response.data)
    },
    createEmployee(data) {
        return instance
            .post(`/employee`, data, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            })
            .then((response) => response.data)
    },
    updateEmployee(id, data) {
        return instance
            .patch(`/employee/${id}`, data, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            })
            .then((response) => response.data)
    },
    deleteEmployee(id) {
        return instance
            .delete(`/employee/${id}`)
            .then((response) => response.data)
    }
}

export const ticketsApi = {
    getTickets(pageNumber, pageSize, searchBy, from, searchingValue, isIncludeFreeTickets) {
        return instance
            .get(
                `/ticket?limit=${pageSize}&count=${pageNumber}&search_by=${searchBy}&from=${from}&searchingValue=${searchingValue}&freeTickets=${isIncludeFreeTickets}`
            )
            .then((response) => response.data)
    },
    createTicket(data) {
        return instance.post(`/ticket`, data).then((response) => response.data)
    },
    resendTicket(data) {
        return instance
            .post("/ticket/resend", data)
            .then((response) => response.data)
    },
    deleteTicket(ticketId) {
        return instance
            .delete(`/ticket/${ticketId}`)
            .then((response) => response.data)
    }
}

export const partnersApi = {
    getPartners(pageNumber, pageSize, searchBy, from, searchingValue) {
        return instance
            .get(
                `/partner?limit=${pageSize}&count=${pageNumber}&search_by=${searchBy}&from=${from}&searchingValue=${searchingValue}`
            )
            .then((response) => response.data)
    },
    createPartner(data) {
        return instance
            .post(`/partner`, data, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            })
            .then((response) => response.data)
    },
    updatePartner(partnerId, data) {
        return instance
            .patch(`/partner/${partnerId}`, data, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            })
            .then((response) => response.data)
    },
    deletePartner(partnerId) {
        return instance
            .delete(`/partner/${partnerId}`)
            .then((response) => response.data)
    }
}

export const newsApi = {
    getNews(pageNumber, pageSize, searchBy, from, searchingValue) {
        return instance
            .get(
                `/news?limit=${pageSize}&count=${pageNumber}&search_by=${searchBy}&from=${from}&searchingValue=${searchingValue}`
            )
            .then((response) => response.data)
    },
    createNews(data) {
        return instance
            .post(`/news`, data, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            })
            .then((response) => response.data)
    },
    updateNews(postId, data) {
        return instance
            .patch(`/news/${postId}`, data, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            })
            .then((response) => response.data)
    },
    deleteNews(postId) {
        return instance
            .delete(`/news/${postId}`)
            .then((response) => response.data)
    }
}

export const promocodeApi = {
    getPromocodes(pageNumber, pageSize, searchBy, from, searchingValue) {
        return instance.get(
            `/promocode?limit=${pageSize}&count=${pageNumber}&search_by=${searchBy}&from=${from}&searchingValue=${searchingValue}`
        )
        .then(response => response.data)
    },
    createPromocode(data) {
        return instance.post('/promocode', data)
        .then(response => response.data)
    },
    updatePromocode(data, promocodeId) {
        return instance.patch(`/promocode/${promocodeId}`, data)
        .then(response => response.data)
    },
    deletePromocode(promocodeId) {
        return instance.delete(`/promocode/${promocodeId}`)
        .then(response => response.data)
    }
}