import { useEffect } from "react"
import { useState } from "react"
import { connect } from "react-redux"
import { createPost, deletePost, getNews, setNewPost, setNewsData, setTotalNews, updatePost } from "../../../Redux/newsReducer"
import Preloader from "../../Common/Preloader/Preloader"
import AdminDeleteModal from "../../UI/Admin/AdminDeleteModal/AdminDeleteModal"
import AdminLayout from "../../UI/Admin/AdminLayout/AdminLayout"
import PageHeader from "../../UI/Admin/PageHeader/PageHeader"
import CreatePost from "./CreatePost"
import EditPost from "./EditPost"
import News from "./News"

const NewsContainer = (props) => {
    const {
        isFetching,
        news,
        total,
        newPost,
        getNews,
        createPost,
        setTotalNews,
        setNewsData,
        deletePost,
        setNewPost,
        updatePost
    } = props

    const [pageSize, setPageSize] = useState(10)
    const [pageNumber, setPageNumber] = useState(0)

    const [searchValue, setSearchValue] = useState("")

    const [currentItem, setCurrentItem] = useState(null)

    const [isOpenAdd, setIsOpenAdd] = useState(false)
    const [isOpenEdit, setIsOpenEdit] = useState(false)
    const [isOpenRemove, setIsOpenRemove] = useState(false)

    const handleOpenAdd = () => {
        setIsOpenAdd(!isOpenAdd)
    }

    const handleOpenEdit = (item) => {
        setCurrentItem(item)
        setIsOpenEdit(!isOpenEdit)
    }

    const handleOpenRemove = (item) => {
        setCurrentItem(item)
        setIsOpenRemove(!isOpenRemove)
    }

    const handleChangePage = (event, page) => {
        setPageNumber(page)
    }

    const handlePageSize = (event) => {
        setPageSize(event.target.value)
        setPageNumber(0)
    }

    const handleAdd = (data) => {
        createPost(data).then(response => {
            if(response) {
                handleOpenAdd()
                localStorage.setItem("add_news_info", "")
            }
        })
    }

    const handleRemove = (postId) => {
        deletePost(postId).then((status) => {
            if(status) {
                const newPosts = [...news]
                newPosts.forEach((item, index) => {
                    if(item._id === postId){
                        newPosts.splice(index, 1)
                        setTotalNews(total - 1)
                    }
                })
                setIsOpenRemove(false)
                setNewsData(newPosts)
            }
        })
    }

    useEffect(() => {
        if(newPost){
            const newPosts = [...news]
            let pushIndex = newPosts.length
            newPosts.forEach((item, index) => {
                if(item._id === newPost._id){
                    newPosts.splice(index, 1)
                    pushIndex = index
                }
            })
            newPosts.splice(pushIndex, 0, newPost)
            setNewsData(newPosts)
            setTotalNews(total + 1)
            setNewPost(null)
        }
    }, [newPost])

    useEffect(() => {
        getNews(pageNumber + 1, pageSize, "", "", searchValue)
    }, [pageSize, pageNumber])

    return (
        <AdminLayout>
            <PageHeader
                title="News"
                onAddClick={handleOpenAdd}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                pageSize={pageSize}
                onSearch={getNews}
            />
            {isOpenAdd && <CreatePost onClose={handleOpenAdd} addPost={handleAdd}/>}
            {isOpenEdit && <EditPost onClose={handleOpenEdit} editPost={updatePost} post={currentItem}/>}
            {isOpenRemove && (
                <AdminDeleteModal
                    deleteItem={handleRemove}
                    onClose={handleOpenRemove}
                    onRemove={handleOpenRemove}
                    item={currentItem}
                    name={currentItem.title}
                />
            )}
            {isFetching && <Preloader/>}
            <News 
                news={news}
                pageSize={pageSize}
                pageNumber={pageNumber}
                total={total}
                handleChangePage={handleChangePage}
                handlePageSize={handlePageSize}
                handleEdit={handleOpenEdit}
                handleRemove={handleOpenRemove}
                onAddClick={handleOpenAdd}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                getNews={getNews}
            />
        </AdminLayout>
    )
}

let mapStateToProps = (state) => ({
    isFetching: state.common.isFetching,
    news: state.news.news,
    total: state.news.total,
    newPost: state.news.newPost
})

export default connect(mapStateToProps, {
    getNews,
    createPost,
    setTotalNews,
    setNewsData,
    deletePost,
    setNewPost,
    updatePost
})(NewsContainer)