import { Button } from '@mui/material'
import React from 'react'
import { useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import Styled from 'styled-components'
import { useAdminSidebar } from '../../../../Hooks/useAdminSidebar'
import classes from './Sidebar.module.css'
import { RiLogoutBoxRLine } from 'react-icons/ri';
import { connect } from 'react-redux'
import { logout } from '../../../../Redux/userReducer'
import { Divider } from '@mui/material'

const Sidebar = (props) => {
    const { logout } = props

    const menuItems = useAdminSidebar()

    const navigate = useNavigate()

    const [isCollapse, setIsCollapse] = useState(false)

    const handleCollapse = () => {
        setIsCollapse(!isCollapse)
    }

    return (
        <Main className={classes.main} isCollapse={isCollapse}>
            {menuItems.map(item => (
                <Button key={item.title} className={classes.linksBut}>
                    <NavLink to={item.href} className={({ isActive }) => isActive ? classes.active : ''}>
                        {item.icon}
                        {!isCollapse && <span>{item.title}</span>}
                    </NavLink>
                </Button>
            ))}
            <Divider className={classes.divider}/>
            <Button className={classes.logout} onClick={logout}>
                <RiLogoutBoxRLine/>
                <span>Exit</span>
            </Button>
        </Main>
    )
}

const Main = Styled.div`
    width: ${({ isCollapse }) => !isCollapse ? "200px" : "70px"};
    transition-duration: .3s;

    div {
        padding: ${({ isCollapse }) => isCollapse ? "0" : "0 20px"};
        box-sizing: border-box;
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
    }
    a {
        justify-content: ${({ isCollapse }) => isCollapse ? "center" : "flex-start"};
    }
    button {
        justify-content: ${({ isCollapse }) => isCollapse ? "center" : "flex-start"};
    }
`

export default connect(null, {
    logout
})(Sidebar)