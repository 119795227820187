const SET_IS_FETCHING = 'SET_IS_FETCHING'
const SET_SERVER_ERROR = 'SET_SERVER_ERROR'
const SET_SERVER_RESPONSE = 'SET_SERVER_RESPONSE'

let initialState = {
    isFetching: false,
    serverError: null,
    serverResponse: null
}

let commonReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_IS_FETCHING: {
            return { ...state, isFetching: action.isFetching }
        }
        case SET_SERVER_ERROR: {
            return { ...state, serverError: action.serverError }
        }
        case SET_SERVER_RESPONSE: {
            return { ...state, serverResponse: action.serverResponse }
        }
        default: 
            return state
    }
}

export const setIsFetching = (isFetching) => ({
    type: SET_IS_FETCHING, isFetching
})
export const setServerError = (serverError) => ({
    type: SET_SERVER_ERROR, serverError
})
export const setServerResponse = (serverResponse) => ({
    type: SET_SERVER_RESPONSE, serverResponse
})

export default commonReducer