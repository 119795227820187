import { combineReducers, applyMiddleware, createStore } from 'redux';
import thunkMiddleWare from 'redux-thunk';
import multi from 'redux-multi';
import commonReducer from './commonReducer';
import eventsReducer from './eventsReducer';
import userReducer from './userReducer';
import employeesReducer from './employeeReducer';
import ticketsReducer from './ticketsReducer';
import partnersReducer from './partnersReducer';
import newsReducer from './newsReducer';
import promocodeReducer from './promocodeReduces';

let reducers = combineReducers({
   common: commonReducer,
   events: eventsReducer,
   user: userReducer,
   employees: employeesReducer,
   tickets: ticketsReducer,
   partners: partnersReducer,
   news: newsReducer,
   promocodes: promocodeReducer
})

let store = createStore(reducers, applyMiddleware(thunkMiddleWare, multi))

window.store = store

export default store