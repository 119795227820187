import React from 'react'
import { cx } from '../../../Utils/classnames'
import classes from './Overflow.module.css'

const Overflow = (props) => {
    const { children, className } = props

    return (
        <div className={cx(classes.main, className)}>
            {children}
        </div>
    )
}

export default Overflow